import React, { useEffect, useMemo, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../components/Common/DeleteModal";
import {
  addNewOperationDefinition as onAddNewOperationDefinition,
  deleteOperationDefinition as onDeleteOperationDefinition,
  getOperationDefinitions as onGetOperationDefinitions,
  updateOperationDefinition as onUpdateOperationDefinition
} from "store/operationDefinition/actions";

//redux
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../components/Common/TableContainer";

// Column
import { Category, Description, Name } from "./operationDefinitionCol";
import { withTranslation } from "react-i18next";
import Notification from "../../components/Notification";
import Select from "react-select";
import { getOperationCategories as onGetOperationCategories } from "../../store/operationCategory/actions";

const OperationDefinition = props => {

  //meta title
  document.title = `CRM | ${props.t("Operation Definition")}`;

  const dispatch = useDispatch();

  const { operationDefinitions, error } = useSelector(state => ({
    operationDefinitions: state.OperationDefinition.operationDefinitions,
    error: state.OperationDefinition.error
  }));

  const { operationCategories } = useSelector(state => ({
    operationCategories: state.OperationCategory.operationCategories,
    error: state.OperationCategory.error
  }));

  const operationCategoryOptions = [];
  operationCategories.map((item) => {
    operationCategoryOptions.push({ label: item.name, value: item });
  });

  const [modal, setModal] = useState(false);
  const [operationDefinitionList, setOperationDefinitionList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [operationDefinition, setOperationDefinition] = useState(null);
  const [firstCall, setFirstCall] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");
  const [selectedOperationCategory, setSelectedOperationCategory] = useState(null);

  // validation
  const validation = {

    values: {
      name: (operationDefinition && operationDefinition.name) || "",
      operationCategory: (operationDefinition && operationDefinition.operationCategory) || "",
      description: (operationDefinition && operationDefinition.description) || ""
    },
    handleSubmit: (values) => {
      if (isEdit) {
        const updateOperationDefinition = {
          id: operationDefinition ? operationDefinition.id : 0,
          name: values.name,
          operationCategory: values.operationCategory,
          description: values.description
        };
        // update operationDefinition
        dispatch(onUpdateOperationDefinition(updateOperationDefinition));
      } else {
        const newOperationDefinition = {
          name: values["name"],
          operationCategory: values["operationCategory"],
          description: values["description"]
        };
        // save new operationDefinition
        dispatch(onAddNewOperationDefinition(newOperationDefinition));
      }
      toggle();
    }
  };

  const handleOperationDefinitionClick = arg => {
    const operationDefinition = arg;

    setOperationDefinition({
      id: operationDefinition.id,
      name: operationDefinition.name,
      operationCategory: operationDefinition.operationCategory,
      description: operationDefinition.description
    });

    setSelectedOperationCategory({
      label: operationDefinition.operationCategory.name,
      value: operationDefinition.operationCategory
    });

    setIsEdit(true);
    toggle();
  };

  useEffect(() => {
    dispatch(onGetOperationCategories());
  }, []);

  const columns = useMemo(
    () => [
      {
        HeaderLabel: `${props.t("Name")}`,
        Header: "İsim",
        accessor: "name",
        filterable: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Category")}`,
        Header: "Category",
        accessor: "operationCategory.name",
        filterable: true,
        Cell: (cellProps) => {
          return <Category {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Description")}`,
        Header: "Açıklama",
        accessor: "description",
        filterable: true,
        Cell: (cellProps) => {
          return <Description {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("isDeletable")}`,
        Header: "Deletable",
        accessor: "deletable",
        filterable: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              {props.t(`${cellProps.row.original.deletable === false ? "Not Deletable" : "Deletable"}`)}
            </div>
          );
        }
      },
      {
        HeaderLabel: `${props.t("Action")}`,
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const operationDefinitionData = cellProps.row.original;
                  handleOperationDefinitionClick(operationDefinitionData);
                }
                }
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {props.t("Edit")}
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const operationDefinitionData = cellProps.row.original;
                  onClickDelete(operationDefinitionData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      }
    ],
    []
  );

  const handleChange = (e) => {
    setOperationDefinition({
      ...operationDefinition,
      [e.target.name]: e.target.value
    });
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOperationDefinition(null);
      setSelectedOperationCategory(null);
    } else {
      setModal(true);
    }
  };

  //delete operationDefinition
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (operationDefinition) => {
    setOperationDefinition(operationDefinition);
    setDeleteModal(true);
  };

  const handleDeleteOperationDefinition = () => {
    if (operationDefinition.id) {
      dispatch(onDeleteOperationDefinition(operationDefinition.id));
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    if (firstCall) {
      dispatch(onGetOperationDefinitions());
      setFirstCall(false);
    }
  }, [dispatch, operationDefinitions]);

  useEffect(() => {
    setOperationDefinitionList(operationDefinitions);
  }, [operationDefinitions]);

  useEffect(() => {
    if (!isEmpty(operationDefinitions)) {
      setOperationDefinitionList(operationDefinitions);
    }
  }, [operationDefinitions]);

  const handleOperationDefinitionClicks = () => {
    setOperationDefinitionList("");
    setIsEdit(false);
    toggle();
  };

  const handleRefresh = () => {
    dispatch(onGetOperationDefinitions());
  };

  const handleClose = () => {
    setMessage("");
    setShowNotification(false);
  };

  useEffect(() => {
    if (error !== undefined && error.data !== undefined) {
      setMessage(error.data.message);
      setShowNotification(true);
      setNotificationType("Danger");
    }
  }, [error]);

  function handleChangeCategory(event) {
    setOperationDefinition({
      ...operationDefinition,
      ["operationCategory"]: event.value
    });
    setSelectedOperationCategory(event);
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOperationDefinition}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Notification
            message={message}
            show={showNotification}
            callbackShow={handleClose}
            type={notificationType}
          />
          <Breadcrumbs title={props.t("Operation Definition")} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    refreshButton={true}
                    handleRefreshClick={handleRefresh}
                    columns={columns}
                    data={operationDefinitions}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    handleAddClick={handleOperationDefinitionClicks}
                    customPageSize={25}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit
                        ? `${props.t("Edit")}`
                        : `${props.t("Add")}`}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit(validation.values);
                          return false;
                        }}
                      >
                        <Row form="true">
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Name")}</Label>
                              <Input
                                name="name"
                                type="text"
                                onChange={handleChange}
                                value={validation.values.name || ""}
                                invalid={!validation.values.name}
                                required={true}
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Category")}</Label>
                              <Select
                                value={selectedOperationCategory}
                                onChange={(event) => {
                                  handleChangeCategory(event);
                                }}
                                options={operationCategoryOptions}
                                className="select2-selection"
                              />
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">{props.t("Description")}</Label>
                              <Input
                                name="description"
                                type="text"
                                onChange={handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.description || ""}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-customer"
                              >
                                {props.t("Save")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
OperationDefinition.propTypes = {
  operationDefinitions: PropTypes.array,
  onGetOperationDefinitions: PropTypes.func,
  onAddNewOperationDefinition: PropTypes.func,
  onDeleteOperationDefinition: PropTypes.func,
  onUpdateOperationDefinition: PropTypes.func
};

export default withRouter(withTranslation()(OperationDefinition));
