import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_ERROR, LOGIN_USER, LOGOUT_USER, LOGIN_SUCCESS, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess } from "./actions";
import { post } from "../../../helpers/api_helper";
import * as url from "../../../helpers/url_helper";

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postJwtLogin, {
      username: user.username,
      password: user.password
    });
    localStorage.setItem("authUser", JSON.stringify(response));
    localStorage.setItem("token", JSON.stringify(response.token));
    yield put(loginSuccess(response));
    if (response.role.name === "ROLE_USER") {
      history.push("/dashboard");
    } else {
      history.push("/admin-dashboard");
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

const postJwtLogin = data => post(url.LOGIN, data);

function* logoutUser({ payload: { history } }) {
  try {
    const user = JSON.parse(localStorage.getItem("authUser"))
    yield call(postLogout, {
      username: user.username
    });
    localStorage.removeItem("authUser");
    localStorage.removeItem("token");
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

const postLogout = data => post(url.LOGOUT, data);

function* socialLogin({ payload: { data, history, type } }) {
  try {
    const response = yield call(postSocialLogin, data);
    localStorage.setItem("authUser", JSON.stringify(response));
    yield put(loginSuccess(response));
    history.push("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

const postSocialLogin = data => post(url.FB_LOGIN, data);

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
}

export default authSaga;
