import React, { useEffect, useState } from "react";

import { Col, Input, InputGroup, Label, Row } from "reactstrap";

import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import PropTypes from "prop-types";
import { postWithoutToken } from "../../helpers/axios_with_headers.js";
import { phoneCodes, phoneOptionsTurkish } from "../../constants/phoneCodesTurkish";
import ReCAPTCHA from "react-google-recaptcha";
import { phoneOptions } from "../../constants/phoneCodes";

// import images

const WebForm = props => {

  //meta title
  document.title = "Web Form";

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [phoneCode, setPhoneCode] = useState(phoneOptionsTurkish[0]);
  const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false);
  const [disableButton, setIsDisableButton] = useState(true);
  const [sendCodeButtonDisabled, setSendCodeButtonDisabled] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const recaptchaRef = React.createRef();

  const handleChange = (e) => {
    switch (e.target.name) {
      case "email":
        setEmail(e.target.value);
        break;
      case "firstName":
        setFirstName(e.target.value);
        break;
      case "lastName":
        setLastName(e.target.value);
        break;
    }
  };

  const onChangePhoneCode = (e) => {
    setPhoneCode({ label: e.target.label, value: e.target.value });
  };

  const handleChangePhone = (e) => {
    let str = e.target.value.toString();
    let phoneCode = "";
    let tel = "";
    if (str.includes("+")) {
      phoneCode = str.split(/\s/)[0];
      findPhoneCode(phoneCode);
      tel = str.split(phoneCode)[1].replaceAll(/\s+/g, "");
    } else {
      tel = str.replaceAll(/\s+/g, "");
    }
    setPhoneNumber(tel);
  };

  const findPhoneCode = (code) => {
    phoneOptionsTurkish.forEach(cd => {
      if (cd.value === code)
        setPhoneCode(cd);
    });
  };

  useEffect(() => {
    const dis = isCaptchaSuccessful && firstName !== "" && lastName !== "" && phoneNumber !== "" && email !== "";
    setIsDisableButton(!dis);
  }, [isCaptchaSuccessful, firstName, lastName, phoneNumber, email]);

  const sendFormDataToBackend = () => {
    const createLeadReq = {
      secretKey: "CN}QSa1nn%KNo?.qu?w+qFNyoPk')hE'",
      createLeads: [{
        email: email,
        fullName: firstName + " " + lastName,
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneCode.value + phoneNumber
      }]
    };

    postWithoutToken(process.env.REACT_APP_API_URL + "/leadIntegration/receiveFromWebsite", createLeadReq).then(r => {
      if (r.status === 200) {
        setIsSent(true);
        setPhoneNumber("");
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneCode(phoneCodes[0]);
        setIsCaptchaSuccess(false);
        setIsDisableButton(true);
        recaptchaRef.current.reset();
      }
    });
  };

  useEffect(() => {
    const handle = setInterval(() => {
      setSendCodeButtonDisabled(false);
    }, 60000);
    return () => {
      clearInterval(handle);
    };
  }, []);

  function onCaptchaChange(value) {
    setIsCaptchaSuccess(true);
  }

  return (
    <div className="page-content-full justify-content-center">
      <div className="vertical-centered-div">
        {!isSent ? (
          <Col xl={12} md={12} xs={12}>
            <Row className="m-2">
              <Col className="col-12">
                <div className="mb-2">
                  <div className="row">
                    <div className="col-6">
                      <Label className="form-label">{props.t("İsim")}</Label>
                      <Input
                        id="firstName"
                        name="firstName"
                        type="text"
                        onChange={handleChange}
                        value={firstName || ""}
                        invalid={!firstName}
                        required={true}
                      />
                    </div>
                    <div className="col-6">
                      <Label className="form-label">{props.t("Soyisim")}</Label>
                      <Input
                        id="lastName"
                        name="lastName"
                        type="text"
                        onChange={handleChange}
                        value={lastName || ""}
                        invalid={!lastName}
                        required={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-2">
                  <Label className="form-label">{props.t("Telefon")}</Label>
                  <InputGroup>
                    <select
                      id="phoneCode"
                      className="form-select"
                      value={phoneCode.value}
                      onChange={onChangePhoneCode}
                    >
                      {phoneOptions.map(code => (
                        <option id={code.value} key={code.label} value={code.value}>
                          {code.label}
                        </option>
                      ))
                      }
                    </select>
                    <Input
                      id="phoneNum"
                      name="phoneNumber"
                      type="text"
                      onChange={handleChangePhone}
                      value={phoneNumber || ""}
                      required={true}
                      placeholder="--- --- ----"
                      invalid={!phoneNumber}
                    />
                  </InputGroup>
                </div>
                <div className="mb-2">
                  <Label className="form-label">{props.t("E-posta")}</Label>
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    onChange={handleChange}
                    value={email}
                    required={false}
                    invalid={email.length === 0}
                  />
                </div>
              </Col>
            </Row>
            <Row className="m-2">
              <Col className="col"></Col>
              <Col className="col">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  name="recaptcha"
                  id="recaptcha"
                  sitekey="6LeA1LclAAAAAHTXdnAwntROBJPIwEteF3NawcAQ"
                  onChange={onCaptchaChange}
                  onExpired={() => {
                    recaptchaRef.current.reset(); // here
                  }}
                  theme="light"
                />
              </Col>
              <Col className="col"></Col>
            </Row>
            <Row className="m-2">
              <Col>
                <div className="text-end mt-2">
                  <button
                    disabled={disableButton}
                    id="save"
                    type="submit"
                    className="btn btn-success"
                    onClick={() => sendFormDataToBackend()}
                  >
                    {props.t("Kaydet")}
                  </button>
                </div>
              </Col>
            </Row>
          </Col>) : (
          <div>
            <p className="text-sm-center">
              <i className="mdi mdi-check-bold text-success display-1" />
              <p className="text-info font-size-24 fw-bold">Teşekkürler</p>
              <p className="text-dark font-size-16 fw-bold mt-2">Başvurunuzu aldık.</p>
              <p className="text-secondary font-size-8 mt-0">Mümkün olan en kısa sürede sizlere geri dönüş sağlayacağız.</p>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

WebForm.propTypes = {
  t: PropTypes.any
};

export default withRouter(withTranslation()(WebForm));