import {
  ADD_CUSTOMER_FAIL,
  ADD_CUSTOMER_SUCCESS,
  ASSIGN_REPRESENTATIVE_FAIL,
  ASSIGN_REPRESENTATIVE_SUCCESS,
  ASSIGN_SEGMENT_FAIL,
  ASSIGN_SEGMENT_SUCCESS,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_FAIL,
  DELETE_CUSTOMER_SUCCESS,
  GET_CUSTOMER_BY_ID_FAIL,
  GET_CUSTOMER_BY_ID_SUCCESS,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_SUCCESS,
  LEAD_CRITERIA_FAIL,
  LEAD_CRITERIA_SUCCESS,
  TRANSFER_LEAD_DATA_FAIL,
  TRANSFER_LEAD_DATA_SUCCESS,
  UPDATE_CUSTOMER_FAIL,
  UPDATE_CUSTOMER_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  customers: [],
  customer: {},
  error: {},
  processResponse: false,
  reqObject: {}
};

const customers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.payload
      };

    case GET_CUSTOMERS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case ADD_CUSTOMER_SUCCESS:
      console.log("reducer", action.payload);
      return {
        ...state,
        customers: [action.payload, ...state.customers],
        processResponse: true
      };

    case ADD_CUSTOMER_FAIL:
      return {
        ...state,
        error: action.payload.data
      };

    case UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        customers: state.customers.map(customer =>
          customer.id.toString() === action.payload.id.toString()
            ? { customer, ...action.payload }
            : customer
        ),
        customer: action.payload,
        processResponse: true
      };

    case UPDATE_CUSTOMER_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case DELETE_CUSTOMER:
      return {
        ...state,
        customer: action.payload
      };

    case DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        customers: state.customers.filter(
          customer => customer.id.toString() !== action.payload.id.toString()
        ),
        processResponse: true
      };

    case DELETE_CUSTOMER_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_CUSTOMER_BY_ID_SUCCESS:
      return {
        ...state,
        customer: action.payload
      };

    case GET_CUSTOMER_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case ASSIGN_REPRESENTATIVE_SUCCESS:
      return {
        ...state,
        customers: state.customers.map(customer => {
          if (action.payload.some(pay => pay.id === customer.id)) {
            const filtered = { ...action.payload.filter(pay => pay.id === customer.id) };
            return filtered[0];
          } else {
            return customer;
          }
        }),
        processResponse: true
      };

    case ASSIGN_REPRESENTATIVE_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case ASSIGN_SEGMENT_SUCCESS:
      return {
        ...state,
        customers: state.customers.map(customer => {
          if (action.payload.some(pay => pay.id === customer.id)) {
            const filtered = { ...action.payload.filter(pay => pay.id === customer.id) };
            return filtered[0];
          } else {
            return customer;
          }
        }),
        processResponse: true
      };

    case ASSIGN_SEGMENT_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case LEAD_CRITERIA_SUCCESS:
      return {
        ...state,
        customers: action.payload.content,
        page: action.payload.pageable.pageNumber ? action.payload.pageable.pageNumber : 0,
        totalCount: action.payload.totalElements,
        totalPages: (action.payload.totalPages - 1)
      };

    case
    LEAD_CRITERIA_FAIL:
      return {
        ...state,
        customers: action.payload
      };

    case TRANSFER_LEAD_DATA_SUCCESS:
      return {
        ...state,
        customers: state.customers.map(customer => {
          if (action.payload.some(pay => pay.id === customer.id)) {
            const filtered = { ...action.payload.filter(pay => pay.id === customer.id) };
            return filtered[0];
          } else {
            return customer;
          }
        }),
        processResponse: true
      };

    case TRANSFER_LEAD_DATA_FAIL:
      return {
        ...state,
        error: action.payload
      };

    default:
      return {
        ...state,
        error: {},
        processResponse: false
      };
  }
};

export default customers;
