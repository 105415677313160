import React, { useEffect, useState } from "react";

import { Card, CardBody, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../store/contacts/actions";

const WelcomeComp = (props) => {
  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem("authUser"));

  const [viewProfileButton, setViewProfileButton] = useState(true);

  useEffect(() => {
    dispatch(getUserProfile(user.id));
  }, [user.id]);

  const { userProfile } = useSelector(state => ({
    userProfile: state.contacts.userProfile
  }));

  useEffect(() => {
    setViewProfileButton(props.viewProfileButton);
  }, [props.viewProfileButton]);

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="8">
              <div className="text-primary p-3">
                <h5 className="text-primary"></h5>
                <p>{props.t("Welcome Back :)")}</p>
              </div>
            </Col>
            <Col xs="4" className="align-self-end">
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="8">
              <div className="avatar-md profile-user-wid mb-4">
                {userProfile.photo !== null && userProfile.photo !== undefined ? (
                  <img
                    src={userProfile.photo.toString()}
                    alt=""
                    className="img-thumbnail avatar-md"
                  />
                ) : (
                  <div className="avatar-md profile-user-wid mb-4">
                    <span className="avatar-title rounded-circle">
                        {userProfile.firstName ? userProfile.firstName.charAt(0) + userProfile.lastName.charAt(0) : "?"}
                    </span>
                  </div>
                )}

              </div>
              <h5 className="font-size-15 text-truncate">{userProfile.firstName + " " + userProfile.lastName}</h5>
            </Col>

            {viewProfileButton === true ? (
              <Col sm="4">
                <div className="mt-4">
                  <Link
                    to="/contacts-profile"
                    className="btn btn-primary  btn-sm"
                  >
                    {props.t("View Profile")} <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
              </Col>
            ) : null}
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

WelcomeComp.propTypes = {
  t: PropTypes.any,
  getUserAppointments: PropTypes.func,
  viewProfileButton: PropTypes.bool
};

export default withTranslation()(WelcomeComp);
