import {
  GET_LEADS_WITH_SEGMENT_CRITERIA,
  GET_LEADS_WITH_SEGMENT_CRITERIA_SUCCESS,
  GET_LEADS_WITH_SEGMENT_CRITERIA_FAIL, GET_LEADS_CRITERIA, GET_LEADS_CRITERIA_SUCCESS, GET_LEADS_CRITERIA_FAIL
} from "./actionTypes";

export const getLeadsBySegmentCriteria = id => ({
  type: GET_LEADS_WITH_SEGMENT_CRITERIA,
  payload: id,
});

export const getLeadsBySegmentCriteriaSuccess = customers => ({
  type: GET_LEADS_WITH_SEGMENT_CRITERIA_SUCCESS,
  payload: customers
});

export const getLeadsBySegmentCriteriaFail = error => ({
  type: GET_LEADS_WITH_SEGMENT_CRITERIA_FAIL,
  payload: error
});

export const getLeadsCriteria = () => ({
  type: GET_LEADS_CRITERIA
});

export const getLeadsCriteriaSuccess = customers => ({
  type: GET_LEADS_CRITERIA_SUCCESS,
  payload: customers
});

export const getLeadsCriteriaFail = error => ({
  type: GET_LEADS_CRITERIA_FAIL,
  payload: error
});