import React, { useEffect, useMemo, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../components/Common/DeleteModal";
import {
  addNewOfferItem as onAddNewOfferItem,
  deleteOfferItem as onDeleteOfferItem,
  getOfferItems as onGetOfferItems,
  updateOfferItem as onUpdateOfferItem
} from "store/offerItem/actions";

//redux
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../components/Common/TableContainer";

// Column
import { DefaultPrice, Description, Name, Price } from "./offerItemCol";
import { withTranslation } from "react-i18next";
import Notification from "../../components/Notification";
import Select from "react-select";

const OfferItem = props => {

  //meta title
  document.title = `CRM | ${props.t("Offer Item")}`;

  const dispatch = useDispatch();

  const initialOfferItem = {
    name: "",
    totalAmount: 0,
    amount: 0,
    taxAmount: 0,
    taxPercentage: 0
  };

  const { offerItems, error } = useSelector(state => ({
    offerItems: state.OfferItem.offerItems,
    error: state.OfferItem.error
  }));

  const [modal, setModal] = useState(false);
  const [offerItemList, setOfferItemList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [offerItem, setOfferItem] = useState(initialOfferItem);
  const [firstCall, setFirstCall] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");
  const [isTemplate, setIsTemplate] = useState(false);
  const [selectedTax, setSelectedTax] = useState({ label: "", value: "" });

  // validation
  const validation = {

    values: {
      name: (offerItem && offerItem.name) || "",
      totalAmount: (offerItem && offerItem.totalAmount) || 0,
      amount: (offerItem && offerItem.amount) || 0,
      description: (offerItem && offerItem.description) || "",
      isTemplate: (offerItem && offerItem.isTemplate) || "",
      taxAmount: (offerItem && offerItem.taxAmount) || 0,
      taxPercentage: (offerItem && offerItem.taxPercentage) || 0,
      currency: (offerItem && offerItem.currency) || ""
    },
    handleSubmit: (values) => {
      if (isEdit) {
        const updateOfferItem = {
          id: offerItem ? offerItem.id : 0,
          name: values.name,
          totalAmount: values.totalAmount,
          amount: values.amount,
          taxAmount: values.taxAmount,
          taxPercentage: values.taxPercentage,
          currency: values.currency,
          description: values.description,
          isTemplate: values.isTemplate
        };
        // update offerItem
        dispatch(onUpdateOfferItem(updateOfferItem));
      } else {
        const newOfferItem = {
          name: values["name"],
          totalAmount: values["totalAmount"],
          amount: values["amount"],
          taxAmount: values["taxAmount"],
          taxPercentage: values["taxPercentage"],
          currency: values["currency"],
          description: values["description"],
          isTemplate: values["isTemplate"]
        };
        // save new offerItem
        dispatch(onAddNewOfferItem(newOfferItem));
      }
      toggle();
    }
  };

  const handleOfferItemClick = arg => {
    const offerItem = arg;

    setOfferItem({
      id: offerItem.id,
      name: offerItem.name,
      totalAmount: offerItem.totalAmount,
      amount: offerItem.amount,
      isTemplate: offerItem.isTemplate,
      taxAmount: offerItem.taxAmount,
      taxPercentage: offerItem.taxPercentage,
      currency: offerItem.currency,
      description: offerItem.description
    });

    setIsTemplate({
      label: offerItem.isTemplate === true ? `${props.t("YES")}` : `${props.t("NO")}`,
      value: offerItem.isTemplate
    });

    setSelectedTax({
      label: offerItem.taxPercentage || "",
      value: offerItem.taxPercentage || ""
    });

    setIsEdit(true);
    toggle();
  };


  const columns = useMemo(
    () => [
      {
        HeaderLabel: `${props.t("Name")}`,
        Header: "İsim",
        accessor: "name",
        filterable: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Amount")}`,
        Header: "Amount",
        accessor: "amount",
        filterable: true,
        Cell: (cellProps) => {
          return <Price {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Tax Amount")}`,
        Header: "Tax Amount",
        accessor: "taxAmount",
        filterable: true,
        Cell: (cellProps) => {
          return <DefaultPrice {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Tax Percentage")}`,
        Header: "Percentage",
        accessor: "taxPercentage",
        filterable: true,
        Cell: (cellProps) => {
          return <Price {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Total Amount")}`,
        Header: "totalAmount",
        accessor: "totalAmount",
        filterable: true,
        Cell: (cellProps) => {
          return <DefaultPrice {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Description")}`,
        Header: "Açıklama",
        accessor: "description",
        filterable: true,
        Cell: (cellProps) => {
          return <Description {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("isTemplate")}`,
        Header: "Template",
        accessor: "isTemplate",
        filterable: true,
        Cell: (cellProps) => {
          const row = cellProps.row.original;
          return (
            <div className="d-flex gap-3">
              {props.t(`${row !== null && row.isTemplate !== null && row.isTemplate === true ? "YES" : "NO"}`)}
            </div>
          );
        }
      },
      {
        HeaderLabel: `${props.t("isDeletable")}`,
        Header: "Deletable",
        accessor: "deletable",
        filterable: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              {props.t(`${cellProps.row.original.deletable === false ? "Not Deletable" : "Deletable"}`)}
            </div>
          );
        }
      },
      {
        HeaderLabel: `${props.t("Action")}`,
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const offerItemData = cellProps.row.original;
                  handleOfferItemClick(offerItemData);
                }
                }
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {props.t("Edit")}
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const offerItemData = cellProps.row.original;
                  onClickDelete(offerItemData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      }
    ],
    []
  );

  const handleChange = (e) => {
    if (e.target.name === "amount") {
      setOfferItem({
        ...offerItem,
        ["amount"]: e.target.value,
        ["totalAmount"]: e.target.value
      });
    } else {
      setOfferItem({
        ...offerItem,
        [e.target.name]: e.target.value
      });
    }
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOfferItem(initialOfferItem);
      setIsTemplate(null);
      setSelectedTax(null);
    } else {
      setModal(true);
    }
  };

  //delete offerItem
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (offerItem) => {
    setOfferItem(offerItem);
    setDeleteModal(true);
  };

  const handleDeleteOfferItem = () => {
    if (offerItem.id) {
      dispatch(onDeleteOfferItem(offerItem.id));
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    if (firstCall) {
      dispatch(onGetOfferItems());
      setFirstCall(false);
    }
  }, [dispatch, offerItems]);

  useEffect(() => {
    setOfferItemList(offerItems);
  }, [offerItems]);

  useEffect(() => {
    if (!isEmpty(offerItems)) {
      setOfferItemList(offerItems);
    }
  }, [offerItems]);

  const handleOfferItemClicks = () => {
    setOfferItemList("");
    setIsEdit(false);
    toggle();
  };

  const handleRefresh = () => {
    dispatch(onGetOfferItems());
  };

  const handleClose = () => {
    setMessage("");
    setShowNotification(false);
  };

  function onChangeTemplateOfferItem(e) {
    setIsTemplate(e);
    setOfferItem({
      ...offerItem,
      ["isTemplate"]: e.value
    });
  }

  const onChangeTaxPercentage = (e) => {
    setSelectedTax(e);
    if (e !== "" && e !== null) {
      setOfferItem({
        ...offerItem,
        ["taxPercentage"]: e.value
      });
    } else {
      setOfferItem({
        ...offerItem,
        ["taxPercentage"]: 0
      });
    }
  };


  useEffect(() => {
    if (error !== undefined && error.data !== undefined) {
      setMessage(error.data.message);
      setShowNotification(true);
      setNotificationType("Danger");
    }
  }, [error]);

  useEffect(() => {
    if (offerItem.taxPercentage !== 0 && offerItem.amount !== 0 || offerItem.amount !== 0 && offerItem.taxAmount !== 0) {
      const taxAmount = ((offerItem.taxPercentage / 100) * offerItem.amount);
      const totalAmount = (+offerItem.amount + +taxAmount);
      setOfferItem({
        ...offerItem,
        ["taxAmount"]: taxAmount,
        ["totalAmount"]: totalAmount
      });
    } else {
      setOfferItem({
        ...offerItem,
        ["totalAmount"]: offerItem.amount
      });
    }
  }, [offerItem.taxAmount, offerItem.taxPercentage, offerItem.amount]);


  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOfferItem}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Notification
            message={message}
            show={showNotification}
            callbackShow={handleClose}
            type={notificationType}
          />
          <Breadcrumbs title={props.t("Offer Item")} />
          <Row>
            <Card>
              <CardBody>
                <TableContainer
                  refreshButton={true}
                  handleRefreshClick={handleRefresh}
                  columns={columns}
                  data={offerItems}
                  isGlobalFilter={true}
                  isAddOptions={true}
                  handleAddClick={handleOfferItemClicks}
                  customPageSize={25}
                  className="custom-header-css"
                />

                <Modal isOpen={modal} toggle={toggle}>
                  <ModalHeader toggle={toggle} tag="h4">
                    {!!isEdit
                      ? `${props.t("Edit")}`
                      : `${props.t("Add")}`}
                  </ModalHeader>
                  <ModalBody>
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit(validation.values);
                        return false;
                      }}
                    >
                      <Row form="true">
                        <div className="col-12">
                          <div className="mb-3">
                            <Label className="form-label">{props.t("Name")}</Label>
                            <Input
                              name="name"
                              type="text"
                              onChange={handleChange}
                              value={validation.values.name || ""}
                              invalid={!validation.values.name}
                              required={true}
                            />
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">{props.t("Amount")}</Label>
                            <Input
                              name="amount"
                              type="text"
                              onChange={handleChange}
                              value={validation.values.amount || ""}
                            />
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">{props.t("Tax Percentage")}</Label>
                            <Select
                              value={selectedTax}
                              onChange={(event) => onChangeTaxPercentage(event)}
                              options={[{ label: "10", value: 10 }, { label: "20", value: 20 }]}
                              className="bs-select"
                              isClearable={true}
                            />
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">{props.t("Tax Amount")}</Label>
                            <Input
                              name="taxAmount"
                              type="text"
                              onChange={handleChange}
                              value={validation.values.taxAmount || 0}
                              disabled={true}
                            />
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">{props.t("Total Amount")}</Label>
                            <Input
                              name="totalAmount"
                              type="text"
                              onChange={handleChange}
                              value={validation.values.totalAmount || ""}
                              invalid={!validation.values.totalAmount}
                              required={true}
                            />
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">{props.t("Template")}</Label>
                            <Select
                              id="isTemplate"
                              name="template-offer-item"
                              onChange={e => onChangeTemplateOfferItem(e)}
                              value={isTemplate}
                              options={[{
                                label: `${props.t("YES")}`,
                                value: true
                              }, { label: `${props.t("NO")}`, value: false }]}
                            />
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">{props.t("Description")}</Label>
                            <Input
                              name="description"
                              type="text"
                              onChange={handleChange}
                              value={validation.values.description || ""}
                            />
                          </div>
                        </div>
                      </Row>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-success save-customer"
                              disabled={validation.values.name === "" || validation.values.totalAmount === 0}
                            >
                              {props.t("Save")}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
OfferItem.propTypes = {
  offerItems: PropTypes.array,
  onGetOfferItems: PropTypes.func,
  onAddNewOfferItem: PropTypes.func,
  onDeleteOfferItem: PropTypes.func,
  onUpdateOfferItem: PropTypes.func
};

export default withRouter(withTranslation()(OfferItem));
