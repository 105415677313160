import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import SimpleBar from "simplebar-react";

import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { getReminderCount, getReminderCriteria } from "../../../store/reminder/actions";
import { DateToString } from "../../../common/commonFunctions";

const NotificationDropdown = props => {
  const dispatch = useDispatch();

  const [menu, setMenu] = useState(false);
  const [count, setCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [filterCreateDateStart, setFilterCreateDateStart] = useState(DateToString(new Date(Date.now()), "YYYY-MM-DD", "-"));
  const [filterCreateDateEnd, setFilterCreateDateEnd] = useState(DateToString(new Date(Date.now()), "YYYY-MM-DD", "-"));

  const user = JSON.parse(localStorage.getItem("authUser"));
  const roleUser = user && user.role && user.role.name === "ROLE_USER";

  const initialBody = !roleUser ? { size: 10, page: 0,
    isCompleted: false,
    reminderDateStart: filterCreateDateStart,
    reminderDateEnd: filterCreateDateEnd } : {
    size: 10,
    page: 0,
    isCompleted: false,
    reminderDateStart: filterCreateDateStart,
    reminderDateEnd: filterCreateDateEnd,
    reminderUser: user.id
  };

  const { reminderCount, reminders } = useSelector(state => ({
    reminderCount: state.Reminder.reminderCount,
    reminders: state.Reminder.reminders
  }));

  useEffect(() => {
    dispatch(getReminderCount());
  }, [reminders]);

  useEffect(() => {
    const handle = setInterval(() => {
      dispatch(getReminderCount());
    }, 60000);
    return () => {
      clearInterval(handle);
    };
  }, [count]);

  useEffect(() => {
    setCount(reminderCount);
  }, [reminderCount]);

  useEffect(() => {
    if (reminderCount > 0) {
      dispatch(getReminderCriteria(initialBody));
    }
  }, [reminderCount]);

  useEffect(() => {
    if (roleUser) {
      const filtered = reminders.filter(mappedReminder => mappedReminder.reminderUser.id === user.id);
      setNotifications(filtered);
    } else {
      setNotifications(reminders);
    }
  }, [reminders]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          <span className="badge bg-danger rounded-pill">{count}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              <div className="col-auto">
                <Link to={`/reminders/${true}`} className="small">
                  {" "}
                  {props.t("View All")}
                </Link>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "150px" }}>
            {notifications.length > 0 && notifications.map((ntf, key) => {
              return (
                // eslint-disable-next-line react/jsx-key
                <Link key={key} to={`/reminders/${true}`} className="text-reset notification-item">
                  <div className="d-flex">
                    <div className="avatar-xs me-3">
                  <span className="avatar-title bg-primary rounded-circle font-size-16">
                    <i className="bx bx-task-x" />
                  </span>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mt-0 mb-1">{ntf.lead.fullName}</h6>
                      <div className="font-size-14 text-muted">
                        <p className="mb-1">
                          {ntf.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any
};