import {
  GET_LATEST_CONTRACTS,
  GET_LATEST_CONTRACTS_FAIL,
  GET_LATEST_CONTRACTS_SUCCESS,
  GET_USER_POLICY,
  GET_USER_POLICY_FAIL,
  GET_USER_POLICY_SUCCESS,
  GET_USER_TERMS,
  GET_USER_TERMS_FAIL,
  GET_USER_TERMS_SUCCESS,
  POST_USER_POLICY,
  POST_USER_POLICY_FAIL,
  POST_USER_POLICY_SUCCESS,
  POST_USER_TERMS,
  POST_USER_TERMS_FAIL,
  POST_USER_TERMS_SUCCESS
} from "./actionTypes";

export const getUserTerms = (id, version) => ({
  type: GET_USER_TERMS,
  id,
  version
});

export const getUserTermsSuccess = terms => ({
  type: GET_USER_TERMS_SUCCESS,
  payload: terms
});

export const getUserTermsFail = error => ({
  type: GET_USER_TERMS_FAIL,
  payload: error
});

export const getUserPolicy = (id, version) => ({
  type: GET_USER_POLICY,
  id,
  version
});

export const getUserPolicySuccess = policy => ({
  type: GET_USER_POLICY_SUCCESS,
  payload: policy
});

export const getUserPolicyFail = error => ({
  type: GET_USER_POLICY_FAIL,
  payload: error
});

export const postUserTerms = (req) => ({
  type: POST_USER_TERMS,
  payload: req
});

export const postUserTermsSuccess = terms => ({
  type: POST_USER_TERMS_SUCCESS,
  payload: terms
});

export const postUserTermsFail = error => ({
  type: POST_USER_TERMS_FAIL,
  payload: error
});

export const postUserPolicy = (req) => ({
  type: POST_USER_POLICY,
  payload: req
});

export const postUserPolicySuccess = policy => ({
  type: POST_USER_POLICY_SUCCESS,
  payload: policy
});

export const postUserPolicyFail = error => ({
  type: POST_USER_POLICY_FAIL,
  payload: error
});


export const getLatestContracts = () => ({
  type: GET_LATEST_CONTRACTS
});

export const getLatestContractsSuccess = contracts => ({
  type: GET_LATEST_CONTRACTS_SUCCESS,
  payload: contracts
});

export const getLatestContractsFail = error => ({
  type: GET_LATEST_CONTRACTS_FAIL,
  payload: error
});
