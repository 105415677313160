import React from "react";
import ReactEcharts from "echarts-for-react";
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";
import { Badge, Col } from "reactstrap";
import { Link } from "react-router-dom";

const PieChart = ({ data }) => {
  const dataColors = "[\"#5045f6\", \"#4777f5\",\"#44aff0\",\"#45dbf7\",\"#f6d54a\",\"#f69846\",\"#ff4343\",\"#f845f1\", \"#ad46f3\",\"#f1c232\",\"#45dbf7\",\"#44aff0\",\"#45dbf7\",\"#f6d54a\",\"#94b585\"]";
  const PieEChartColors = getChartColorsArray(dataColors);
  const chartData = data.map(item => ({
    key: item.id,
    name: `${item.name} (${item.count})`,
    value: item.count,
    id: item.id
  }));

  const options = {
    legend: {
      orient: "vertical",
      left: "left",
      textStyle: {
        color: ["#8791af"]
      },
      data: data.map((item) => `${item.name} (${item.count})`)
    },
    series: {
      name: "Müşteri sayısı",
      type: "pie",
      radius: "45%",
      center: ["65%", "50%"],
      data: chartData,
      itemStyle: {
        emphasis: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)"
        }
      },
      label: {
        alignTo: "center",
        minMargin: 5,
        edgeDistance: 10,
        lineHeight: 15
      }
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)"
    },
    color: PieEChartColors
  };
  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "350px" }} option={options} notMerge={true}
                    lazyUpdate={true} />
      <Col id="chartData" xl={12} className="mt-3">
        {chartData.map((item, index) => (
          // eslint-disable-next-line react/jsx-key
          <React.Fragment>
            <Link
              id={item.id}
              key={item.id}
              to={`/customers-with-segment/${item.id}`}
            >
              <Badge id={`badge-${item.id}`} pill className="badge-soft bg-opacity-10 text-opacity-100 ms-1"
                     style={{ color: PieEChartColors[index] }}>
                {item.name}
              </Badge>
            </Link>
          </React.Fragment>
        ))}
      </Col>
    </React.Fragment>
  );
};
export default PieChart;
