import React from "react";
import { Redirect } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// //Customers
import Customers from "../pages/Customers";
import CustomerImport from "../pages/Customers/CustomerImport";
import CustomerDetail from "../pages/Customers/CustomerDetail";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Login2 from "../pages/AuthenticationInner/Login2";
import Register1 from "../pages/AuthenticationInner/Register";
import Register2 from "../pages/AuthenticationInner/Register2";
import Recoverpw from "../pages/AuthenticationInner/Recoverpw";
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";
import ForgetPwd2 from "../pages/AuthenticationInner/ForgetPassword2";
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";
import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2";
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail";
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2";
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification";
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2";
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification";
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2";

//Contacts
import ContactsGrid from "../pages/Contacts/contacts-grid";
import ContactsList from "../pages/Contacts/ContactList/contacts-list";
import ContactsProfile from "../pages/Contacts/ContactsProfile/contacts-profile";
import Segment from "../pages/Segment";
import Category from "../pages/Category";
import Resource from "../pages/Resource";
import Reference from "../pages/Reference";
import Dashboard from "../pages/Dashboard";
import Appointment from "../pages/Appointment";
import Reminder from "../pages/Reminder";
import AdminDashboard from "../pages/AdminDashboard";
import Report from "../pages/Report";
import ReportList from "../pages/Report/ReportList";
import IntegrationDefinition from "../pages/IntegrationDefinition";
import PrivacyPolicy from "../pages/templates/PrivacyPolicy";
import TermsAndConditions from "../pages/templates/TermsAndConditions";
import Integration from "../pages/Integration";
import SystemParameter from "../pages/SystemParameter";
import LeadHistory from "../pages/LeadHistory";
import FacebookForm from "../pages/FacebookForm";
import Languages from "../pages/Languages";
import FacebookLoginHistory from "../pages/FacebookLoginHistory";
import UserLoginHistory from "../pages/UserLoginHistory";
import LeadIntegrationDefinition from "../pages/LeadIntegrationDefinition";
import Hospital from "../pages/Hospital";
import Doctor from "../pages/Doctor";
import OperationCategory from "../pages/OperationCategory";
import OperationDefinition from "../pages/OperationDefinition";
import OfferItem from "../pages/OfferItem";
import Offer from "../pages/Offer";
import MedicalOperation from "../pages/MedicalOperation";
import DescriptionTemplate from "../pages/DescriptionTemplate";
import Pages500 from "../pages/Utility/Pages500";
import Pages404 from "../pages/Utility/Pages404";
import WebFormVerified from "../pages/WebFormVerified";
import WebFormTr from "../pages/WebForm/tr";
import WebFormEn from "../pages/WebForm/en";
import WebFormDe from "../pages/WebForm/de";
import WebFormFr from "../pages/WebForm/fr";

const user = JSON.parse(localStorage.getItem("authUser")) ;
const userCheck = user && user.role && user.role.name === "ROLE_USER"

const authProtectedRoutes = [

  { path: "/dashboard", component: Dashboard },
  { path: "/admin-dashboard", component: AdminDashboard },
  // //profile
  { path: "/profile", component: UserProfile },

  //Customers
  { path: "/customers", component: Customers },
  { path: "/customer-import", component: CustomerImport },
  { path: "/customer-detail", component: CustomerDetail },

  //segments
  { path: "/segments", component: Segment },

  //category
  { path: "/categories", component: Category },

  //resources
  { path: "/resources", component: Resource },

  //references
  { path: "/references", component: Reference },

  //forms
  { path: "/forms", component: FacebookForm },

  //Integration
  { path: "/integration", component: Integration },
  { path: "/integrationDefinition", component: IntegrationDefinition },
  { path: "/lead-integration-definition", component: LeadIntegrationDefinition },

  // Contacts
  { path: "/contacts-grid", component: ContactsGrid },
  { path: "/contacts-list", component: ContactsList },
  { path: "/contacts-profile", component: ContactsProfile },

  //appointments
  { path: "/appointments", component: Appointment },

  //reminders
  { path: "/reminders", component: Reminder },
  //criteria
  { path: "/reminders/:ntf", component: Reminder },

  //criteria
  { path: "/customers-with-segment/:segmentId", component: Customers },

  //reports
  { path: "/reports", component: Report },
  { path: "/report-list", component: ReportList },

  //system parameters
  { path: "/system-parameter", component: SystemParameter },

  //languages
  { path: "/languages", component: Languages },

  //history
  { path: "/lead-history", component: LeadHistory },
  { path: "/facebook-login-history", component: FacebookLoginHistory },
  { path: "/user-login-history", component: UserLoginHistory },

  //Operation Parameters
  { path: "/hospital", component: Hospital },
  { path: "/doctor", component: Doctor },
  { path: "/operation-category", component: OperationCategory },
  { path: "/operation-definition", component: OperationDefinition },
  { path: "/medical-operation", component: MedicalOperation },
  { path: "/offer-item", component: OfferItem },
  { path: "/offer", component: Offer },
  { path: "/description-template", component: DescriptionTemplate },

  //templates
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/terms-and-conditions", component: TermsAndConditions },
  { path: "/error-page-500", component: Pages500 },
  { path: "/error-page-404", component: Pages404 },

  { path: "/", exact: true, component: userCheck ? () => <Redirect to="/dashboard" /> : () => <Redirect to="/admin-dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/web-form-verify", component: WebFormVerified }, // with SMS verification Turkish Form - (medulux için yapıldı bu hariç diğerleri doğrulamasız meduluxta)
  { path: "/web-form/tr", component: WebFormTr }, // with SMS verification TODO: medulux a alrken dikkat
  { path: "/web-form/en", component: WebFormEn }, // with SMS verification TODO: medulux a alrken dikkat
  { path: "/web-form/de", component: WebFormDe },
  { path: "/web-form/fr", component: WebFormFr },

  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-login-2", component: Login2 },
  { path: "/pages-register", component: Register1 },
  { path: "/pages-register-2", component: Register2 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/page-recoverpw-2", component: Recoverpw2 },
  { path: "/pages-forgot-pwd", component: ForgetPwd1 },
  { path: "/auth-recoverpw-2", component: ForgetPwd2 },
  { path: "/auth-lock-screen", component: LockScreen },
  { path: "/auth-lock-screen-2", component: LockScreen2 },
  { path: "/page-confirm-mail", component: ConfirmMail },
  { path: "/page-confirm-mail-2", component: ConfirmMail2 },
  { path: "/auth-email-verification", component: EmailVerification },
  { path: "/auth-email-verification-2", component: EmailVerification2 },
  { path: "/auth-two-step-verification", component: TwostepVerification },
  { path: "/auth-two-step-verification-2", component: TwostepVerification2 },

  //templates
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/terms-and-conditions", component: TermsAndConditions },
  { path: "/error-page-500", component: Pages500 },
  { path: "/error-page-404", component: Pages404 },
];

export { authProtectedRoutes, publicRoutes };
