//REGISTER
export const REGISTER = "/auth/signup";

//AUTH
export const LOGIN = "/auth/signin";
export const LOGOUT = "/auth/logout";
export const FB_LOGIN = "/oauth2/facebook";
export const GET_LOGIN_ATTEMPTS = "/authLog/getAll/0/20"
export const GET_USER_LOGIN_ATTEMPTS = "/authLog/getByLoggedInUser"
export const GET_AUTH_LOGS = "/authLog/findByParameters"

export const GET_FACEBOOK_AUTH_LOG = "/facebookIntegrationLog/getAll"

//PROFILE
export const EDIT_PROFILE = "/edit_profile";

//CUSTOMERS
export const GET_CUSTOMERS = "/lead/getAll";
export const ADD_NEW_CUSTOMER = "/lead";
export const UPDATE_CUSTOMER = "/lead";
export const DELETE_CUSTOMER = "/lead";
export const GET_CUSTOMER_BY_ID = "/lead";
export const SAVE_IMAGES = "/photo/byLead";
export const GET_PHOTOS = "/photo/getByLeadId";
//setSalesRepresentative
export const SET_REPRESENTATIVE = "/lead/assignSalesRepresentative";
export const SET_SEGMENT = "/lead/assignSegment";
export const TRANSFER_DATA = "/leadIntegration/sendToCRM"

//SEGMENTS
export const GET_SEGMENTS = "/segment/getAll";
export const ADD_NEW_SEGMENT = "/segment";
export const UPDATE_SEGMENT = "/segment";
export const DELETE_SEGMENT = "/segment";

//RESOURCES
export const GET_RESOURCES = "/resource/getAll";
export const ADD_NEW_RESOURCE = "/resource";
export const UPDATE_RESOURCE = "/resource";
export const DELETE_RESOURCE = "/resource";

//CATEGORIES
export const GET_CATEGORIES = "/category/getAll";
export const ADD_NEW_CATEGORY = "/category";
export const UPDATE_CATEGORY = "/category";
export const DELETE_CATEGORY = "/category";

//CONTACTS
export const GET_USERS = "/users/getAll";
export const GET_USER_PROFILE = "/users";
export const ADD_NEW_USER = "/users";
export const UPDATE_USER = "/users";
export const DELETE_USER = "/users";
export const GET_USERS_BY_ROLE = "/users/getAllByRole";
export const CHANGE_PASSWORD = "/users/changePassword";

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data";
export const GET_YEARLY_DATA = "/yearly-data";
export const GET_MONTHLY_DATA = "/monthly-data";

//ROLES
export const GET_ROLES = "/roles/getAll";
export const ADD_NEW_ROLE = "/roles";
export const UPDATE_ROLE = "/roles";
export const DELETE_ROLE = "/roles";

// NOTES
export const GET_NOTES = "/note/getAll";
export const ADD_NEW_NOTE = "/note";
export const UPDATE_NOTE = "/note";
export const DELETE_NOTE = "/note";
export const GET_LEAD_NOTES = "/note/getByLeadId";

// IMPORT
export const IMPORT_EXCEL = "/fileImport/excel";
export const IMPORT_CSV = "/fileImport/csv";

//REFERENCES
export const GET_REFERENCES = "/reference/getAll";
export const ADD_NEW_REFERENCE = "/reference";
export const UPDATE_REFERENCE = "/reference";
export const DELETE_REFERENCE = "/reference";

// APPOINTMENTS
export const GET_APPOINTMENTS = "/appointment/getAll";
export const ADD_NEW_APPOINTMENT = "/appointment";
export const UPDATE_APPOINTMENT = "/appointment";
export const DELETE_APPOINTMENT = "/appointment";
export const GET_LEAD_APPOINTMENT = "/appointment/getByLeadId";
export const GET_USER_APPOINTMENTS = "/appointment/getByCreateUserId";
export const GET_TODAY_APPOINTMENT = "/appointment/getTodaysAppointments";

// CRITERIA
export const GET_LEADS_CRITERIA_WITH_SEGMENT = "/lead/getCriteria";
export const LEADS_CRITERIA = "/lead/findByParameters";
export const USER_CRITERIA = "/users/findByParameters";

// REPORT
export const GET_LEAD_REPORTS_BY_USER = "/report/leadReportByUser";
export const GET_LEAD_COUNTS_BY_SEGMENT = "/report/leadCountsReportBySegment";
export const LEAD_REPORTS_BY_DATE_CRITERIA = "/report/leadReportByCreateDate";

//LANGUAGE
export const GET_LANGUAGES = "/language/getAll";
export const ADD_NEW_LANGUAGE = "/language";
export const UPDATE_LANGUAGE = "/language";
export const DELETE_LANGUAGE = "/language";

//Integration Definition
export const GET_FORMS = "/facebookIntegrationDefinition/getAll";
export const ADD_NEW_FORM = "/facebookIntegrationDefinition";
export const UPDATE_FORM = "/facebookIntegrationDefinition";
export const DELETE_FORM = "/facebookIntegrationDefinition";

//Facebook Form
export const GET_FACEBOOK_FORMS = "/facebookForm/getAll";
export const ADD_NEW_FACEBOOK_FORM = "/facebookForm";
export const UPDATE_FACEBOOK_FORM = "/facebookForm";
export const DELETE_FACEBOOK_FORM = "/facebookForm";

//platform
export const GET_PLATFORMS = "/platform/getAll";
export const ADD_NEW_PLATFORM = "/platform";
export const UPDATE_PLATFORM = "/platform";
export const DELETE_PLATFORM = "/platform";

// REMINDERS
export const GET_REMINDERS = "/reminder/getAll";
export const ADD_NEW_REMINDER = "/reminder";
export const UPDATE_REMINDER = "/reminder";
export const DELETE_REMINDER = "/reminder";
export const GET_LEAD_REMINDER = "/reminder/getByLeadId";
export const GET_USER_REMINDERS = "/reminder/getByCreateUserId";
export const GET_TODAY_REMINDER = "/reminder/getTodaysReminders";

//REMINDER CRITERIA
export const REMINDER_CRITERIA = "/reminder/findByParameters";

//APPOINTMENT CRITERIA
export const APPOINTMENT_CRITERIA = "/appointment/findByParameters";

//Notificaitons
export const GET_NOTIFICATION_COUNT = "/reminder/getCurrentUserTodaysRemindersCount";

//PARAMETER
export const GET_PARAMETERS = "/systemParameter/getAll";
export const ADD_NEW_PARAMETER = "/systemParameter";
export const UPDATE_PARAMETER = "/systemParameter";
export const DELETE_PARAMETER = "/systemParameter";

export const SYSTEM_PARAMETER_BY_CODE = "/systemParameter/findByCode";
export const GET_ACTIVE_USER_COUNT = "/users/findByParametersCount";

//LEAD HISTORY
export const LEAD_HISTORY = "/leadHistory/getAll";
export const LEAD_HISTORY_BY_ENTITY_ID = "/leadHistory/entityId";
export const LEAD_HISTORY_PAGEABLE = "/leadHistory/getAll";
export const LEAD_HISTORY_BY_FILTER = "/leadHistory/getByFilter";

//APPOINTMENT HISTORY
export const APPOINTMENT_HISTORY = "/appointmentHistory/getAll";
export const APPOINTMENT_HISTORY_BY_ENTITY_ID = "/appointmentHistory/entityId";
export const APPOINTMENT_HISTORY_PAGEABLE = "/appointmentHistory/getAll";
export const APPOINTMENT_HISTORY_BY_FILTER = "/appointmentHistory/getByFilter";

//REMINDER HISTORY
export const REMINDER_HISTORY = "/reminderHistory/getAll";
export const REMINDER_HISTORY_BY_ENTITY_ID = "/reminderHistory/entityId";
export const REMINDER_HISTORY_PAGEABLE = "/reminderHistory/getAll";
export const REMINDER_HISTORY_BY_FILTER = "/reminderHistory/getByFilter";

//NOTE HISTORY
export const NOTE_HISTORY = "/noteHistory/getAll";
export const NOTE_HISTORY_BY_ENTITY_ID = "/noteHistory/entityId";
export const NOTE_HISTORY_PAGEABLE = "/noteHistory/getAll";
export const NOTE_HISTORY_BY_FILTER = "/noteHistory/getByFilter";

//TERMS AND POLICY
export const GET_TERMS_INFO = "/userContract/userId";
export const GET_PRIVACY_INFO = "/userContract/userId";
export const POST_TERMS_INFO = "/userContract";
export const POST_PRIVACY_INFO = "/userContract";

//CONTRACTS
export const LATEST_CONTRACTS = "/contract/getLatestContracts";

//LEAD_INTEGRATION_DEFINITION
export const LEAD_INT_DEF = "/leadIntegrationDefinition";

//DOCTOR
export const GET_DOCTORS = "/doctor/getAll";
export const ADD_NEW_DOCTOR = "/doctor";
export const UPDATE_DOCTOR = "/doctor";
export const DELETE_DOCTOR = "/doctor";

//HOSPITAL
export const GET_HOSPITALS = "/hospital/getAll";
export const ADD_NEW_HOSPITAL = "/hospital";
export const UPDATE_HOSPITAL = "/hospital";
export const DELETE_HOSPITAL = "/hospital";

//Operation Definition
export const GET_OPERATION_DEFINITIONS = "/operationDefinition/getAll";
export const ADD_NEW_OPERATION_DEFINITION  = "/operationDefinition";
export const UPDATE_OPERATION_DEFINITION = "/operationDefinition";
export const DELETE_OPERATION_DEFINITION  = "/operationDefinition";

//Operation Category
export const GET_OPERATION_CATEGORY = "/operationCategory/getAll";
export const ADD_NEW_OPERATION_CATEGORY = "/operationCategory";
export const UPDATE_OPERATION_CATEGORY = "/operationCategory";
export const DELETE_OPERATION_CATEGORY = "/operationCategory";

//Operation
export const GET_MEDICAL_OPERATION = "/medicalOperation/getAll";
export const ADD_NEW_MEDICAL_OPERATION = "/medicalOperation";
export const UPDATE_MEDICAL_OPERATION = "/medicalOperation";
export const DELETE_MEDICAL_OPERATION = "/medicalOperation";

//Offer Items
export const OFFER_ITEM_TEMPLATES = "/offerItem/findTemplates"
export const GET_OFFER_ITEMS = "/offerItem/getAll";
export const ADD_NEW_OFFER_ITEM = "/offerItem";
export const UPDATE_OFFER_ITEM = "/offerItem";
export const DELETE_OFFER_ITEM = "/offerItem";

//Offer Items
export const OFFER_TEMPLATES = "/offer/findTemplates"
export const GET_OFFERS = "/offer/getAll";
export const ADD_NEW_OFFER = "/offer";
export const UPDATE_OFFER = "/offer";
export const DELETE_OFFER = "/offer";

// ADD_NEW_DESCRIPTION_TEMPLATE
export const GET_DESCRIPTION_TEMPLATES = "/descriptionTemplate/getAll";
export const ADD_NEW_DESCRIPTION_TEMPLATE = "/descriptionTemplate"
export const UPDATE_DESCRIPTION_TEMPLATE = "/descriptionTemplate";
export const DELETE_DESCRIPTION_TEMPLATE = "/descriptionTemplate";