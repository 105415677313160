import {
  GET_LEADS_CRITERIA_FAIL,
  GET_LEADS_CRITERIA_SUCCESS,
  GET_LEADS_WITH_SEGMENT_CRITERIA,
  GET_LEADS_WITH_SEGMENT_CRITERIA_FAIL,
  GET_LEADS_WITH_SEGMENT_CRITERIA_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  customers: [],
  customer: {},
  error: {},
  processResponse: false
};

const Criteria = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LEADS_WITH_SEGMENT_CRITERIA_SUCCESS:
      return {
        ...state,
        customers: action.payload
      };

    case GET_LEADS_WITH_SEGMENT_CRITERIA_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_LEADS_CRITERIA_SUCCESS:
      return {
        ...state,
        customers: action.payload
      };

    case GET_LEADS_CRITERIA_FAIL:
      return {
        ...state,
        error: action.payload
      };

    default:
      return {
        ...state,
      };
  }
};

export default Criteria;
