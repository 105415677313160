import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table
} from "reactstrap";
import { Link } from "react-router-dom";

import classNames from "classnames";

//import Charts
import StackedColumnChart from "./StackedColumnChart";

//import action
import { getChartsData as onGetChartsData, getLatestContracts, getUserPolicy, getUserTerms } from "../../store/actions";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import SocialSource from "./SocialSource";
import ActivityComp from "./ActivityComp";
import LastLogins from "./LastLogins";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useDispatch, useSelector } from "react-redux";

import { getSegments as onGetSegments } from "../../store/segment/actions";

import { getLeadCountsReportBySegment as onGetLeadCounts } from "../../store/report/actions";
import PieChart from "../../components/Common/PieChart";
import TermsAndPrivacyModal from "../TermsAndPrivacyModal";

const user = JSON.parse(localStorage.getItem("authUser") !== null && localStorage.getItem("authUser"));
const AdminDashboard = props => {
  const dispatch = useDispatch();

  const [modal, setmodal] = useState(false);
  const [openTermsAndPrivacyModal, setOpenTermsAndPrivacyModal] = useState(false);
  const [policy, setPolicy] = useState();
  const [terms, setTerms] = useState();
  const [loggedInUser, setLoggedInUser] = useState();

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData
  }));

  useEffect(()=>{
    setLoggedInUser(user);
  },[user])


  const [periodData, setPeriodData] = useState([]);
  const [periodType, setPeriodType] = useState("yearly");

  useEffect(() => {
    setPeriodData(chartsData);
  }, [chartsData]);

  const onChangeChartPeriod = pType => {
    setPeriodType(pType);
    dispatch(onGetChartsData(pType));
  };

  useEffect(() => {
    dispatch(getLatestContracts());
  }, [dispatch]);

  useEffect(() => {
    if (policy !== undefined && loggedInUser.id !== undefined) {
      dispatch(getUserPolicy(loggedInUser.id, policy.version));
    }
  }, [policy, loggedInUser]);

  useEffect(() => {
    if (terms !== undefined && loggedInUser.id !== undefined ) {
      dispatch(getUserTerms(loggedInUser.id, terms.version));
    }
  }, [terms, loggedInUser]);

  useEffect(() => {
    dispatch(onGetSegments());
  }, [dispatch]);

  useEffect(() => {
    dispatch(onGetLeadCounts());
  }, [dispatch]);

  const { segmentReports } = useSelector(state => ({
    segmentReports: state.Report.segmentReports
  }));

  const { contracts, policyResponse, termsResponse } = useSelector(state => ({
    contracts: state.TermsAndPolicy.contracts,
    policyResponse: state.TermsAndPolicy.policyResponse,
    termsResponse: state.TermsAndPolicy.termsResponse
  }));

  useEffect(() => {
    if (contracts.length > 0) {
      contracts.forEach(contract => {
        if (contract.contractType === "PRIVACY_POLICY") {
          setPolicy(contract);
        } else if (contract.contractType === "TERMS_CONDITIONS") {
          setTerms(contract);
        }
      });

    }
  }, [contracts]);

  useEffect(() => {
    if (termsResponse.length === 0 || policyResponse.length === 0) {
      setOpenTermsAndPrivacyModal(true);
    }
  }, [termsResponse, policyResponse]);

  //meta title
  document.title = `CRM | ${props.t("Dashboard")}`;

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboard")}
          />
          <TermsAndPrivacyModal
            modal={openTermsAndPrivacyModal}
            terms={terms}
            policy={policy}
          />
          <Row>
            <Col xl="3">
              <WelcomeComp viewProfileButton={true} />
            </Col>
            <Col xl="9">
              <Card className="mini-stats-wid">
                <CardBody>
                  <ActivityComp viewMoreButton={true} />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Card className="mini-stats-wid">
              <CardTitle>
                {props.t("Segment Chart")}
              </CardTitle>
              <CardBody>
                <PieChart
                  key="pieAdmin"
                  id="adminSegmentPie"
                  data={segmentReports}
                />
              </CardBody>
            </Card>
          </Row>

          <Row>
            <Col xl="4">
              <SocialSource t={props.t} />
            </Col>
            <Col xl="4">
              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4"></h4>
                    <div className="ms-auto">
                      <ul className="nav nav-pills">
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "weekly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("weekly");
                            }}
                            id="one_month"
                          >
                            {props.t("Week")}
                          </Link>{" "}
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "monthly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("monthly");
                            }}
                            id="one_month"
                          >
                            {props.t("Month")}
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="#"
                            className={classNames(
                              { active: periodType === "yearly" },
                              "nav-link"
                            )}
                            onClick={() => {
                              onChangeChartPeriod("yearly");
                            }}
                            id="one_month"
                          >
                            {props.t("Year")}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <div className="clearfix"></div> */}
                  <StackedColumnChart periodData={periodData} />
                </CardBody>
              </Card>
            </Col>
            <Col xl="4">
              <LastLogins
                t={props.t}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodal(!modal);
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setmodal(!modal);
            }}
          >
            Order Details
          </ModalHeader>
          <ModalBody>
            <p className="mb-2">
              Product id: <span className="text-primary">#SK2540</span>
            </p>
            <p className="mb-4">
              Billing Name: <span className="text-primary">Neal Matthews</span>
            </p>

            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <thead>
                <tr>
                  <th scope="col">Product</th>
                  <th scope="col">Product Name</th>
                  <th scope="col">Price</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th scope="row">
                    <div>
                      <img src={modalimage1} alt="" className="avatar-sm" />
                    </div>
                  </th>
                  <td>
                    <div>
                      <h5 className="text-truncate font-size-14">
                        Wireless Headphone (Black)
                      </h5>
                      <p className="text-muted mb-0">$ 225 x 1</p>
                    </div>
                  </td>
                  <td>$ 255</td>
                </tr>
                <tr>
                  <th scope="row">
                    <div>
                      <img src={modalimage2} alt="" className="avatar-sm" />
                    </div>
                  </th>
                  <td>
                    <div>
                      <h5 className="text-truncate font-size-14">
                        Hoodie (Blue)
                      </h5>
                      <p className="text-muted mb-0">$ 145 x 1</p>
                    </div>
                  </td>
                  <td>$ 145</td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <h6 className="m-0 text-end">Sub Total:</h6>
                  </td>
                  <td>$ 400</td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <h6 className="m-0 text-end">Shipping:</h6>
                  </td>
                  <td>Free</td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <h6 className="m-0 text-end">Total:</h6>
                  </td>
                  <td>$ 400</td>
                </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setmodal(!modal);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  );
};

AdminDashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func
};

export default withTranslation()(AdminDashboard);
