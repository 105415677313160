import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import PropTypes from "prop-types";
import { postUserPolicy, postUserTerms } from "../../store/TermsAndPrivacy/actions";
import { getUserProfile } from "../../store/contacts/actions";
import { withTranslation } from "react-i18next";

const loggedInUser = JSON.parse(localStorage.getItem("authUser"));

const TermsAndPrivacyModal = props => {

  const dispatch = useDispatch();

  const [termsModal, setTermsModal] = useState(false);
  const [privacyModal, setPrivacyModal] = useState(false);
  const [termsReaded, setTermsReaded] = useState(false);
  const [privacyReaded, setPrivacyReaded] = useState(false);
  const [termsAndPrivacyModal, setTermsAndPrivacyModal] = useState(false);
  const [termsTemplate, setTermsTemplate] = useState({ __html: "" });
  const [policyTemplate, setPolicyTemplate] = useState({ __html: "" });

  useEffect(() => {
    setTermsAndPrivacyModal(props.modal);
    if (props.terms !== undefined) {
      setTermsTemplate({ __html: props.terms.content });
    }
    if (props.policy !== undefined) {
      setPolicyTemplate({ __html: props.policy.content });
    }
  }, [props.modal, props.policy, props.terms]);

  useEffect(() => {
    if (loggedInUser !== null) {
      dispatch(getUserProfile(loggedInUser.id));
    }
  }, [loggedInUser]);

  const { userProfile } = useSelector(state => ({
    userProfile: state.contacts.userProfile
  }));

  function sendApproveResponseToBackend() {
    const userTermsReq = Object.assign({ user: userProfile, contract: props.terms });
    const userPolicyReq = Object.assign({ user: userProfile, contract: props.policy });
    dispatch(postUserPolicy(userPolicyReq));
    dispatch(postUserTerms(userTermsReq));
    setTermsAndPrivacyModal(!termsAndPrivacyModal);
  }

  return (
    <Modal
      isOpen={termsAndPrivacyModal}
      role="dialog"
      centered
      backdrop="static"
      data-toggle="modal"
      toggle={() => {
        setTermsAndPrivacyModal(!termsAndPrivacyModal);
        setTermsReaded(false);
        setPrivacyReaded(false);
      }}
    >
      <div>
        <ModalHeader
          className="border-bottom-0"
        >
          <span className="text-info text-center">
            {props.t("You have to accept Terms & Conditions, Privacy Policy to continue!")}
          </span>
        </ModalHeader>
      </div>
      <div className="modal-body">
        <div className="text-center mb-4">
          <div className="avatar-md mx-auto mb-4">
            <div className="avatar-title bg-light  rounded-circle text-primary h1">
              <i className="dripicons-contract-2"></i>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-xl-10">
              <div className="mt-20 text-center">
                <div className="mt-70 text-center">
                  <input type="checkbox"
                         id="customCheckcolor4"
                         checked={termsReaded}
                         onChange={() => setTermsReaded(termsReaded)} /> I accept <span
                  className="text-primary" onClick={() => {
                  setTermsReaded(!termsReaded), setTermsModal(!termsModal);
                }}>Terms & Conditions</span>
                  <Modal
                    isOpen={termsModal}
                    role="dialog"
                    autoFocus={true}
                    centered={true}
                    size="xl"
                    toggle={() => {
                      setTermsModal(!termsModal);
                    }}
                  >
                    <div>
                      <ModalHeader
                        toggle={() => {
                          setTermsModal(!termsModal);
                        }}
                      >
                      </ModalHeader>
                      <ModalBody>
                        <span dangerouslySetInnerHTML={termsTemplate} />
                      </ModalBody>
                    </div>
                  </Modal>
                </div>
                <div className="mt-70 text-center">
                  <input type="checkbox"
                         id="customCheckcolor4"
                         onChange={() => setPrivacyReaded(privacyReaded)}
                         checked={privacyReaded}
                  /> I read <span
                  className="text-primary" onClick={() => {
                  setPrivacyReaded(!privacyReaded), setPrivacyModal(!privacyModal);
                }}>Privacy Policy</span>
                  <Modal
                    isOpen={privacyModal}
                    role="dialog"
                    autoFocus={true}
                    centered={true}
                    size="xl"
                    toggle={() => {
                      setPrivacyModal(!privacyModal);
                    }}
                  >
                    <div>
                      <ModalHeader
                        toggle={() => {
                          setPrivacyModal(!privacyModal);
                        }}
                      >
                      </ModalHeader>
                      <ModalBody>
                        <span dangerouslySetInnerHTML={policyTemplate} />
                      </ModalBody>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalFooter style={{height: 70}}>
        <div className="container-sm">
          <Button
            color="primary"
            className="horizontal-centered-button btn-rounded me-2"
            onClick={() => sendApproveResponseToBackend()}
            disabled={!termsReaded || !privacyReaded}
          >{props.t("Approve")}</Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
TermsAndPrivacyModal.propTypes = {
  t: PropTypes.any,
  modal: PropTypes.bool,
  terms: PropTypes.any,
  policy: PropTypes.any
};
export default withTranslation()(TermsAndPrivacyModal);