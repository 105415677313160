import {
  IMPORT_EXCEL,
  IMPORT_EXCEL_SUCCESS,
  IMPORT_EXCEL_ERROR,
  IMPORT_CSV,
  IMPORT_CSV_SUCCESS,
  IMPORT_CSV_ERROR
} from "./actionTypes";

const INIT_STATE = {
  excelFile: [],
  csvFile: [],
  importSuccess: false,
  error: {}
};

const FileImport = (state = INIT_STATE, action) => {
  switch (action.type) {
    case IMPORT_EXCEL_SUCCESS:
      return {
        ...state,
        excelFile: [...state.excelFile, action.payload]
      };

    case IMPORT_EXCEL_ERROR:
      return {
        ...state,
        error: action.payload.response.data
      };

    case IMPORT_CSV_SUCCESS:
      return {
        ...state,
        csvFile: [...state.csvFile, action.payload],
        importSuccess: true
      };

    case IMPORT_CSV_ERROR:
      return {
        ...state,
        importSuccess: false,
        error: action.payload.response.data
      };
    default:
      return {
        ...state,
        excelFile: [],
        csvFile: [],
        importSuccess: false,
        error: {}
      };
  }
};

export default FileImport;
