import React, { useEffect, useMemo, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../components/Common/DeleteModal";
import {
  addNewSegment as onAddNewSegment,
  deleteSegment as onDeleteSegment,
  getSegments as onGetSegments,
  updateSegment as onUpdateSegment
} from "store/segment/actions";

//redux
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../components/Common/TableContainer";

// Column
import { Code, Description, Name } from "./segmentCol";
import { withTranslation } from "react-i18next";
import Notification from "../../components/Notification";
import Select from "react-select";

const Segment = props => {

  //meta title
  document.title = `CRM | ${props.t("Segments")}`;

  const dispatch = useDispatch();

  const { segments, error } = useSelector(state => ({
    segments: state.segments.segments,
    error: state.segments.error
  }));

  const [modal, setModal] = useState(false);
  const [segmentList, setSegmentList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [segment, setSegment] = useState(null);
  const [firstCall, setFirstCall] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");
  const [status, setStatus] = useState({ label: "", value: "" });

  // validation
  const validation = {

    values: {
      name: (segment && segment.name) || "",
      code: (segment && segment.code) || "",
      status: (segment && segment.status) || "",
      description: (segment && segment.description) || ""
    },

    handleSubmit: (values) => {
      if (isEdit) {
        const updateSegment = {
          id: segment ? segment.id : 0,
          name: values.name,
          code: values.code,
          status: values.status,
          description: values.description
        };
        dispatch(onUpdateSegment(updateSegment));
      } else {
        const newSegment = {
          name: values["name"],
          code: values["code"],
          status: values["status"],
          description: values["description"]
        };
        // save new segment
        dispatch(onAddNewSegment(newSegment));
      }
      toggle();
    }
  };

  const statusOptions = [{ label: props.t("ACTIVE"), value: "ACTIVE" },
    { label: props.t("PASSIVE"), value: "PASSIVE" }];

  const handleSegmentClick = arg => {
    const segment = arg;

    setSegment({
      id: segment.id,
      name: segment.name,
      code: segment.code,
      status: segment.status,
      description: segment.description
    });

    setStatus({ label: `${props.t(segment.status)}`, value: segment.status });
    setIsEdit(true);
    toggle();
  };

  const handleChange = (e) => {
    setSegment({
      ...segment,
      [e.target.name]: e.target.value
    });
  };

  // Customber Column
  const columns = useMemo(
    () => [
      {
        HeaderLabel: `${props.t("Name")}`,
        Header: "İsim",
        accessor: "name",
        filterable: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Code")}`,
        Header: "Kod",
        accessor: "code",
        filterable: true,
        Cell: (cellProps) => {
          return <Code {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Description")}`,
        Header: "Açıklama",
        accessor: "description",
        filterable: true,
        Cell: (cellProps) => {
          return <Description {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Status")}`,
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              {props.t(`${cellProps.row.original.status}`)}
            </div>
          );
        }
      },
      {
        HeaderLabel: `${props.t("isDeletable")}`,
        Header: "Deletable",
        accessor: "deletable",
        filterable: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              {props.t(`${cellProps.row.original.deletable === false ? "Not Deletable" : "Deletable"}`)}
            </div>
          );
        }
      },
      {
        HeaderLabel: `${props.t("Action")}`,
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const segmentData = cellProps.row.original;
                  handleSegmentClick(segmentData);
                }
                }
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {props.t("Edit")}
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const segmentData = cellProps.row.original;
                  onClickDelete(segmentData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      }
    ],
    []
  );

  const toggle = () => {
    if (modal) {
      setModal(false);
      setSegment(null);
      setStatus("");
    } else {
      setModal(true);
    }
  };

  //delete segment
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (segment) => {
    setSegment(segment);
    setDeleteModal(true);
  };

  const handleDeleteSegment = () => {
    if (segment.id) {
      dispatch(onDeleteSegment(segment.id));
      setDeleteModal(false);
      setSegment(null);
    }
  };

  useEffect(() => {
    if (firstCall) {
      dispatch(onGetSegments());
      setFirstCall(false);
    }
  }, [dispatch, segments]);

  useEffect(() => {
    setSegmentList(segments);
  }, [segments]);

  useEffect(() => {
    if (!isEmpty(segments)) {
      setSegmentList(segments);
    }
  }, [segments]);

  const handleSegmentClicks = () => {
    setSegmentList("");
    setIsEdit(false);
    toggle();
  };

  const handleRefresh = () => {
    dispatch(onGetSegments());
  };

  const handleClose = () => {
    setMessage("");
    setShowNotification(false);
  };

  function onChangeStatus(e) {
    setStatus(e);
    setSegment({
      ...segment,
      ["status"]: e.value
    });
  }

  useEffect(() => {
    if (error !== undefined && error.data !== undefined) {
      setMessage(error.data.message);
      setShowNotification(true);
      setNotificationType("Danger");
    }
  }, [error]);

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteSegment}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Notification
            message={message}
            show={showNotification}
            callbackShow={handleClose}
            type={notificationType}
          />
          <Breadcrumbs title={props.t("Segments")} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    refreshButton={true}
                    handleRefreshClick={handleRefresh}
                    columns={columns}
                    data={segments}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    handleAddClick={handleSegmentClicks}
                    customPageSize={25}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit
                        ? `${props.t("Edit")}`
                        : `${props.t("Add")}`}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit(validation.values);
                          return false;
                        }}
                      >
                        <Row form="true">
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Name")}</Label>
                              <Input
                                name="name"
                                type="text"
                                onChange={handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                                required={true}
                                invalid={!validation.values.name}
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Code")}</Label>
                              <Input
                                name="code"
                                type="text"
                                onChange={handleChange}
                                value={validation.values.code || ""}
                                disabled={true}
                              />
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">{props.t("Description")}</Label>
                              <Input
                                name="description"
                                type="text"
                                onChange={handleChange}
                                value={validation.values.description || ""}
                              />
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">{props.t("Status")}</Label>
                              <Select
                                id="integration-status"
                                className="bs-select"
                                value={status}
                                onChange={(e) => {
                                  onChangeStatus(e);
                                }}
                                options={statusOptions}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-customer"
                              >
                                {props.t("Save")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
Segment.propTypes = {
  segments: PropTypes.array,
  onGetSegments: PropTypes.func,
  onAddNewSegment: PropTypes.func,
  onDeleteSegment: PropTypes.func,
  onUpdateSegment: PropTypes.func
};

export default withRouter(withTranslation()(Segment));
