import {
  ADD_CUSTOMER_FAIL,
  ADD_CUSTOMER_SUCCESS,
  ADD_NEW_CUSTOMER,
  ASSIGN_REPRESENTATIVE,
  ASSIGN_REPRESENTATIVE_FAIL,
  ASSIGN_REPRESENTATIVE_SUCCESS,
  ASSIGN_SEGMENT,
  ASSIGN_SEGMENT_FAIL,
  ASSIGN_SEGMENT_SUCCESS,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_FAIL,
  DELETE_CUSTOMER_SUCCESS,
  GET_CUSTOMER_BY_ID,
  GET_CUSTOMER_BY_ID_FAIL,
  GET_CUSTOMER_BY_ID_SUCCESS,
  GET_CUSTOMERS,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_SUCCESS,
  LEAD_CRITERIA,
  LEAD_CRITERIA_FAIL,
  LEAD_CRITERIA_SUCCESS, TRANSFER_LEAD_DATA, TRANSFER_LEAD_DATA_FAIL, TRANSFER_LEAD_DATA_SUCCESS,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_FAIL,
  UPDATE_CUSTOMER_SUCCESS
} from "./actionTypes";

export const getCustomers = () => ({
  type: GET_CUSTOMERS
});

export const getCustomersSuccess = customers => ({
  type: GET_CUSTOMERS_SUCCESS,
  payload: customers
});

export const getCustomersFail = error => ({
  type: GET_CUSTOMERS_FAIL,
  payload: error
});

export const addNewCustomer = customer => ({
  type: ADD_NEW_CUSTOMER,
  payload: customer
});

export const addCustomerSuccess = customer => ({
  type: ADD_CUSTOMER_SUCCESS,
  payload: customer
});

export const addCustomerFail = error => ({
  type: ADD_CUSTOMER_FAIL,
  payload: error
});

export const updateCustomer = customer => ({
  type: UPDATE_CUSTOMER,
  payload: customer
});

export const updateCustomerSuccess = customer => ({
  type: UPDATE_CUSTOMER_SUCCESS,
  payload: customer
});

export const updateCustomerFail = error => ({
  type: UPDATE_CUSTOMER_FAIL,
  payload: error
});

export const deleteCustomer = customer => ({
  type: DELETE_CUSTOMER,
  payload: customer
});

export const deleteCustomerSuccess = customer => ({
  type: DELETE_CUSTOMER_SUCCESS,
  payload: customer
});

export const deleteCustomerFail = error => ({
  type: DELETE_CUSTOMER_FAIL,
  payload: error
});

export const getCustomerById = id => ({
  type: GET_CUSTOMER_BY_ID,
  id
});

export const getCustomerByIdSuccess = customer => ({
  type: GET_CUSTOMER_BY_ID_SUCCESS,
  payload: customer
});

export const getCustomerByIdFail = error => ({
  type: GET_CUSTOMER_BY_ID_FAIL,
  payload: error
});

export const assignRepresentative = req => ({
  type: ASSIGN_REPRESENTATIVE,
  payload: req
});

export const assignRepresentativeSuccess = customer => ({
  type: ASSIGN_REPRESENTATIVE_SUCCESS,
  payload: customer
});

export const assignRepresentativeFail = error => ({
  type: ASSIGN_REPRESENTATIVE_FAIL,
  payload: error
});

export const assignSegment = req => ({
  type: ASSIGN_SEGMENT,
  payload: req
});

export const assignSegmentSuccess = customer => ({
  type: ASSIGN_SEGMENT_SUCCESS,
  payload: customer
});

export const assignSegmentFail = error => ({
  type: ASSIGN_SEGMENT_FAIL,
  payload: error
});

export const customerCriteria = res => ({
  type: LEAD_CRITERIA,
  payload: res
});

export const customerCriteriaSuccess = (res) => ({
  type: LEAD_CRITERIA_SUCCESS,
  payload: res
});

export const customerCriteriaFail = error => ({
  type: LEAD_CRITERIA_FAIL,
  payload: error
});

export const transferData = req => ({
  type: TRANSFER_LEAD_DATA,
  payload: req
});

export const transferDataSuccess = customer => ({
  type: TRANSFER_LEAD_DATA_SUCCESS,
  payload: customer
});

export const transferDataFail = error => ({
  type: TRANSFER_LEAD_DATA_FAIL,
  payload: error
});