import React, { useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router-dom";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip
} from "reactstrap";


import { CDate, Email, FirstName, Languages, LastName, Name, Phone, Status } from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import { getRoles as onGetRoles } from "store/roles/actions";
import { getLanguages as onGetLanguages } from "store/language/actions";
import { getCategories as onGetCategories } from "store/category/actions";

import {
  addNewUser as onAddNewUser,
  deleteUser as onDeleteUser,
  updateUser as onUpdateUser,
  userCriteria
} from "store/contacts/actions";

//redux
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import Notification from "../../../components/Notification";
import ChangePassword from "../../../components/Common/ChangePassword";
import { fileToBase64 } from "../../../common/commonFunctions";
import { get, post } from "../../../helpers/axios_with_headers";
import * as url from "../../../helpers/url_helper";
import categories from "../../../store/category/reducer";

const ContactsList = props => {

  //meta title
  document.title = `CRM | ${props.t("Users")}`;

  const initalCont = {
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    role: "",
    categories: null,
    languages: null,
    status: "ACTIVE"
  };

  const dispatch = useDispatch();
  const [role, setRole] = useState({ label: "", value: "" });
  const [deleteUser, setDeleteUser] = useState([]);
  const [roleUser, setRoleUser] = useState(false);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [contact, setContact] = useState(initalCont);
  const [passwordRetype, setPasswordRetype] = useState("");
  const [loading, setLoading] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");
  const [password, setPassword] = useState("");
  const [passValid, setPassValid] = useState(false);
  const [alert, setAlert] = useState(false);
  const [passModal, setPassModal] = useState(false);
  const [selectedMulti, setselectedMulti] = useState(null);
  const [status, setStatus] = useState({ label: "ACTIVE", value: "ACTIVE" });
  const [limitationExist, setLimitationExist] = useState(false);
  const [activeUserCount, setActiveUserCount] = useState("");
  const [userLimit, setUserLimit] = useState("");
  const [passiveCheck, setPassiveCheck] = useState(false);
  const [multiCategories, setMultiCategories] = useState(null);

  useEffect(() => {
    let userReq = {};
    if (!passiveCheck) {
      userReq = Object.assign({ notRole: "ROLE_MODERATOR", status: "ACTIVE" });
    } else {
      userReq = Object.assign({ notRole: "ROLE_MODERATOR" });
    }
    dispatch(userCriteria(userReq));
  }, [passiveCheck]);

  const { users } = useSelector(state => ({
    users: state.contacts.users
  }));

  useEffect(() => {
    get(url.SYSTEM_PARAMETER_BY_CODE + "/USE_USER_LIMITATION").then(response => {
      if (response.data && response.data.value === "NO") {
        setLimitationExist(false);
      } else {
        setLimitationExist(true);
        get(url.SYSTEM_PARAMETER_BY_CODE + "/USER_COUNT").then(response => {
          if (response.data && response.data.value) {
            setUserLimit(response.data.value);
          }
        });
        const countBody = Object.assign({ status: "ACTIVE", notRole: "ROLE_MODERATOR" });
        post(url.GET_ACTIVE_USER_COUNT, countBody).then(response => {
          setActiveUserCount(response.data);
        });
      }
    });
  }, []);

  useEffect(() => {
    const loggedInUser = JSON.parse(localStorage.getItem("authUser"));
    setRoleUser(loggedInUser && loggedInUser.role && loggedInUser.role.name === "ROLE_USER");
  }, [roleUser]);

  useEffect(() => {
    const langs = contact.languages !== null ? contact.languages.map(lang => ({
      label: lang.name,
      value: lang
    })) : null;
    setselectedMulti(langs);
  }, [contact]);


  function handleMulti(selectedMulti) {
    const langCodes = selectedMulti.map((lang) => lang.value);
    setselectedMulti(selectedMulti);
    setContact({
      ...contact,
      ["languages"]: langCodes
    });
  }

  useEffect(() => {
    const cats = contact.categories !== null ? contact.categories.map(cat => ({
      label: cat.name,
      value: cat
    })) : null;
    setMultiCategories(cats);
  }, [contact]);


  function handleCatMulti(data) {
    const catCodes = data.map((cat) => cat.value);
    setMultiCategories(data);
    setContact({
      ...contact,
      ["categories"]: catCodes
    });
  }

  const { languages } = useSelector(state => ({
    languages: state.Languages.languages
  }));

  const languageOptions = [];
  languages.map((item) => {
    languageOptions.push({ label: item.name, value: item });
  });

  const { categories } = useSelector(state => ({
    categories: state.categories.categories
  }));

  const categoryOptions = [];
  categories.map((item) => {
    categoryOptions.push({ label: item.name, value: item });
  });

  const { roles } = useSelector(state => ({
    roles: state.roles.roles
  }));

  const roleOptions = [];
  roles.map((item) => {
    if (item.name !== "ROLE_MODERATOR") {
      roleOptions.push({ label: item.name, value: item });
    }
  });

  const statusOptions = [{ label: "ACTIVE", value: "ACTIVE" },
    { label: "PASSIVE", value: "PASSIVE" },
    { label: "BLOCKED", value: "BLOCKED" }];

  const onChangeInRoleSelect = event => {
    setContact({
      ...contact,
      ["role"]: event.value
    });
    setRole(event);
  };

  const onChangeInStatusSelect = event => {
    setContact({
      ...contact,
      ["status"]: event.value
    });
    setStatus(event);
  };

  const columns = useMemo(
    () => [
      {
        HeaderLabel: `${props.t("Avatar")}`,
        Header: "Avatar",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => {
          let convertedPhoto = null;
          if (cellProps.photo !== null) {
            convertedPhoto = cellProps.photo.toString();
          }
          return (convertedPhoto !== null ? (
            <img src={convertedPhoto} style={{ height: 50, width: 50, flex: 1 }} />
          ) : (
            <div style={{ height: 50, width: 50, flex: 1 }}>
                <span className="avatar-title rounded-circle">
                        {cellProps.username ? cellProps.username.charAt(0) : "?"}
                </span>
            </div>
          ));
        }
      },
      {
        HeaderLabel: `${props.t("Username")}`,
        Header: "Kullanıcı Adı",
        accessor: "username",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Firstname")}`,
        Header: "İsim",
        accessor: "firstName",
        filterable: true,
        Cell: cellProps => {
          return <FirstName {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Lastname")}`,
        Header: "Soyisim",
        accessor: "lastName",
        filterable: true,
        Cell: cellProps => {
          return <LastName {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Phone")}`,
        Header: "Phone",
        accessor: "phoneNumber",
        filterable: true,
        Cell: cellProps => {
          return <Phone {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Email")}`,
        Header: "Email",
        accessor: "email",
        filterable: true,
        Cell: cellProps => {
          return <Email {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Languages")}`,
        Header: "Languages",
        accessor: "languages",
        filterable: true,
        Cell: cellProps => {
          return <Languages {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Categories")}`,
        Header: "Categories",
        accessor: "categories",
        filterable: true,
        Cell: cellProps => {
          return <Languages {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Status")}`,
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Create Date")}`,
        Header: "Cdate",
        accessor: "createDate",
        filterable: true,
        Cell: cellProps => {
          return <CDate {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Action")}`,
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Button
                color="warning"
                outline
                onClick={() => {
                  toggleChangePassword(true), setContact(cellProps.row.original);
                }}
                disabled={roleUser}
              >
                <i className="mdi mdi-lock font-size-18" id="resetpasstooltip" />
                <UncontrolledTooltip placement="top" target="resetpasstooltip">
                  {props.t("Reset Password")}
                </UncontrolledTooltip>
              </Button>
              <Button
                color="danger"
                outline
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
                disabled={roleUser}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Button>
            </div>
          );
        }
      }
    ],
    [roleUser]
  );

  useEffect(() => {
    dispatch(onGetRoles());
  }, []);

  useEffect(() => {
    dispatch(onGetLanguages());
  }, []);

  useEffect(() => {
    dispatch(onGetCategories());
  }, []);

  const toggle = () => {
    if (modal) {
      setContact(initalCont);
      setRole({ label: "", value: "" });
      setStatus({ label: "", value: "" });
      setModal(false);
      setIsEdit(false);
      setPassword("");
      setPasswordRetype("");
      setPassValid(false);
      setselectedMulti(null);
      setMultiCategories(null);
    } else {
      setModal(true);
    }
  };

  const handleUserClick = arg => {
    const user = arg.original;

    setContact({
      id: user.id,
      username: user.username,
      firstName: user.firstName,
      lastName: user.lastName,
      password: user.password,
      phoneNumber: user.phoneNumber,
      email: user.email,
      languages: user.languages,
      categories: user.categories,
      role: user.role,
      status: user.status
    });
    user.role ? setRole({ label: user.role.name, value: user.role }) : null;
    user.status ? setStatus({ label: user.status, value: user.status }) : null;
    setIsEdit(true);

    toggle();
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = user => {
    setDeleteUser(user);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    dispatch(onDeleteUser(deleteUser.id));
    setDeleteModal(false);
    setLoading(true);
  };

  const handleRefresh = () => {
    const userReq = Object.assign({ notRole: "ROLE_MODERATOR", status: "ACTIVE" });
    dispatch(userCriteria(userReq));
  };

  const handleChangePass = (e) => {
    const regEXp = /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-99!@#$%^&*.]{8,99}$/;
    const trimmedPass = e.target.value.trim();
    setAlert(true);
    setPassword(trimmedPass);
    if (regEXp.test(trimmedPass)) {
      setPassValid(true);
      setAlert(false);
      setContact({
        ...contact,
        ["password"]: trimmedPass
      });
    }
  };

  const handleChange = (e) => {
    setContact({
      ...contact,
      [e.target.name]: e.target.value
    });
  };

  const handleChangePhoto = (e) => {
    fileToBase64(e.target.files[0]).then(response => {
      setContact({
        ...contact,
        ["photo"]: response
      });
    });

  };

  const handleChangePassRetype = (e) => {
    setPasswordRetype(e.target.value);
  };

  const handleClose = () => {
    setMessage("");
    setShowNotification(false);
  };

  const handleClickWithoutPermission = () => {
    setShowNotification(true);
    setMessage(`${props.t("You have no permission for this action")}`);
    setNotificationType("Warning");
  };

  const saveUser = () => {
    if (isEdit) {
      dispatch(onUpdateUser(contact));
    } else {
      if (!passValid || contact.password !== passwordRetype) {
        setMessage(`${props.t("Passwords must match and contain numbers and letters and must be 8 characters long may include (!@#$%^&*.)")}`);
        setShowNotification(true);
        setNotificationType("Warning");
      } else if (contact.username === "" || contact.phoneNumber === "" || contact.email === "") {
        setMessage(`${props.t("Please fill mandatory fields")}`);
        setShowNotification(true);
        setNotificationType("Warning");
      } else {
        dispatch(onAddNewUser(contact));
      }
    }
    toggle();
  };

  const { errorMessage } = useSelector(state => state.contacts.error);

  useEffect(() => {
    if (errorMessage) {
      setMessage(errorMessage);
      setShowNotification(true);
      setNotificationType("Danger");
    }
  }, [errorMessage]);

  const toggleChangePassword = (bool) => {
    setPassModal(bool);
  };

  const toolbar = (
    <div id="toolbar" className="d-inline-block align-content-end">
      <Row className="mt-0">
        <div className="form-check form-check-left mb-3">
          <label
            className="form-check-label"
            onClick={() => setPassiveCheck(!passiveCheck)}
          >
            <input
              type="checkbox"
              className="form-check-input"
              id="getPassiveUsers"
              onChange={() => setPassiveCheck(passiveCheck)}
              checked={passiveCheck}
            />
            {props.t("Include Passive/Blocked Users")}
          </label>
        </div>
        <span className="align-middle p-3 text-info mb-3">
                  {limitationExist && (props.t("Your remaining user creation limit is: ") + activeUserCount + " / " + userLimit)}
                </span>
      </Row>
    </div>
  );

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Notification
        message={message}
        show={showNotification}
        callbackShow={handleClose}
        type={notificationType}
      />
      {!roleUser && passModal && (<ChangePassword
          modal={passModal}
          user={contact}
          callbackModalResponse={toggleChangePassword}
        />
      )}
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={props.t("Users")} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    refreshButton={true}
                    handleRefreshClick={handleRefresh}
                    columns={columns}
                    data={users}
                    isGlobalFilter={true}
                    isAddOptions={!roleUser}
                    handleAddClick={toggle}
                    handleRowClick={!roleUser ? handleUserClick : handleClickWithoutPermission}
                    customPageSize={25}
                    toolbar={toolbar}
                    className="custom-header-css"
                  />
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? `${props.t("Edit")}` : `${props.t("Add")}`}
                    </ModalHeader>
                    <ModalBody>
                      <Row form="true">
                        <Col xs={12}>
                          <div className="mb-3">
                            <Label className="form-label">{props.t("Username")}</Label>
                            <Input
                              name="username"
                              type="text"
                              onChange={handleChange}
                              value={contact.username || ""}
                              disabled={roleUser}
                              invalid={!contact.username}
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">{props.t("Firstname")}</Label>
                            <Input
                              name="firstName"
                              label="İsim"
                              type="text"
                              onChange={handleChange}
                              disabled={roleUser}
                              value={contact.firstName || ""}
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">{props.t("Lastname")}</Label>
                            <Input
                              name="lastName"
                              label="Soyisim"
                              type="text"
                              onChange={handleChange}
                              disabled={roleUser}
                              value={contact.lastName || ""}
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">{props.t("Email")}</Label>
                            <Input
                              name="email"
                              label="Email"
                              type="email"
                              onChange={handleChange}
                              disabled={roleUser}
                              value={contact.email || ""}
                              invalid={!contact.email}
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">{props.t("Phone")}</Label>
                            <Input
                              name="phoneNumber"
                              label="Phone"
                              type="text"
                              onChange={handleChange}
                              value={contact.phoneNumber || ""}
                              invalid={!contact.phoneNumber}
                            />
                          </div>
                          {!isEdit ? (<>
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Password")}</Label>
                              {alert === true ? (<Alert color="warning" style={{ marginTop: "3px" }}>
                                {props.t("Password must contain numbers and letters and must be 8 characters long may include (!@#$%^&*.)")}
                              </Alert>) : null}
                              <Input
                                name="password"
                                type="password"
                                value={password}
                                onChange={handleChangePass}
                                invalid={!passValid}
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Password Retype")}</Label>
                              {passwordRetype !== contact.password ? (
                                <Alert color="warning" style={{ marginTop: "3px" }}>
                                  {props.t("Passwords do not match!!!")}
                                </Alert>) : null}
                              <Input
                                name="passwordReType"
                                type="password"
                                value={passwordRetype}
                                onChange={handleChangePassRetype}
                                invalid={passwordRetype !== contact.password}
                              />
                            </div>
                          </>) : null}
                          <div className="mb-3">
                            <label className="control-label">
                              {props.t("Languages")}
                            </label>
                            <Select
                              value={selectedMulti}
                              isMulti={true}
                              onChange={(event) => {
                                handleMulti(event);
                              }}
                              placeholder={props.t("Select")}
                              options={languageOptions}
                              className="select2-selection"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="control-label">
                              {props.t("Categories")}
                            </label>
                            <Select
                              value={multiCategories}
                              isMulti={true}
                              onChange={(event) => {
                                handleCatMulti(event);
                              }}
                              placeholder={props.t("Select")}
                              options={categoryOptions}
                              className="select2-selection"
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">{props.t("Photo")}</Label>
                            <Input
                              id="photo"
                              name="photo"
                              type="file"
                              onChange={handleChangePhoto}
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">{props.t("Role")} <i
                              className="mdi mdi-alert-circle-outline error me-1" /></Label>
                            <Select
                              value={role}
                              onChange={(e) => {
                                onChangeInRoleSelect(e);
                              }}
                              options={roleOptions}
                            />
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">{props.t("Status")} <i
                              className="mdi mdi-alert-circle-outline error me-1" /></Label>
                            <Select
                              value={status}
                              onChange={(e) => {
                                onChangeInStatusSelect(e);
                              }}
                              options={statusOptions}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-success save-user"
                              onClick={saveUser}
                              disabled={!isEdit && (!passValid || contact.password !== passwordRetype || contact.username === "" || contact.email === "" || contact.phoneNumber === "") || status.value === "" || role.value === ""}
                            >
                              {props.t("Save")}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(withTranslation()(ContactsList));
