import React, { useEffect, useState } from "react";
import { Button, Container, Input, Label } from "reactstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import CustomDataTable from "../../../components/Common/CustomDataTable";
import { getSegments as onGetSegments } from "../../../store/segment/actions";
import { getCategories as onGetCategories } from "../../../store/category/actions";
import { getResources as onGetResources } from "../../../store/resource/actions";
import { getReferences as onGetReferences } from "../../../store/reference/actions";
import { getFacebookForms as onGetForms } from "../../../store/FacebookForms/actions";
import { post } from "../../../helpers/axios_with_headers";
import * as url from "../../../helpers/url_helper";
import Select from "react-select";

const Report = (props) => {
  const dispatch = useDispatch();

  const [firstCall, setFirstCall] = useState(true);
  const [segmentData, setSegmentData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [resourceData, setResourceData] = useState([]);
  const [referenceData, setReferenceData] = useState([]);

  const loggedInUser = JSON.parse(localStorage.getItem("authUser"));
  const roleUser = loggedInUser && loggedInUser.role && loggedInUser.role.name === "ROLE_USER";

  const [reports, setReports] = useState([]);
  const [filterCreateDateStart, setFilterCreateDateStart] = useState("");
  const [filterCreateDateEnd, setFilterCreateDateEnd] = useState("");
  const [filterUpdateDateStart, setFilterUpdateDateStart] = useState("");
  const [filterUpdateDateEnd, setFilterUpdateDateEnd] = useState("");
  const [filterCategory, setFilterCategory] = useState();
  const [filterFacebookForm, setFilterFacebookForm] = useState();

  const body = {
    createDateStart: filterCreateDateStart,
    createDateEnd: filterCreateDateEnd,
    updateDateStart: filterUpdateDateStart,
    updateDateEnd: filterUpdateDateEnd,
    category: filterCategory && filterCategory.value.id,
    facebookForm: filterFacebookForm && filterFacebookForm.value.id
  };

  const { categories } = useSelector(state => ({
    categories: state.categories.categories
  }));

  const { segments } = useSelector(state => ({
    segments: state.segments.segments
  }));

  const { resources } = useSelector(state => ({
    resources: state.resources.resources
  }));

  const { references } = useSelector(state => ({
    references: state.references.references
  }));

  const { facebookForms } = useSelector(state => ({
    facebookForms: state.FacebookForms.facebookForms
  }));

  const formNameOptions = [];
  facebookForms.map((item) => {
    formNameOptions.push({ label: item.formName, value: item });
  });

  const categoryOptions = [];
  categories.map((item) => {
    categoryOptions.push({ label: item.name, value: item });
  });

  function handleFilterCreateDateStart(e) {
    setFilterCreateDateStart(e.target.value);
  }

  function handleFilterCreateDateEnd(e) {
    setFilterCreateDateEnd(e.target.value);
  }

  function handleFilterUpdateDateStart(e) {
    setFilterUpdateDateStart(e.target.value);
  }

  function handleFilterUpdateDateEnd(e) {
    setFilterUpdateDateEnd(e.target.value);
  }

  function handleFilterFacebookForm(e) {
    setFilterFacebookForm(e);
  }

  function handleFilterCategory(e) {
    setFilterCategory(e);
  }

  useEffect(() => {
    post(url.LEAD_REPORTS_BY_DATE_CRITERIA, body).then(response => setReports(response.data));
  }, []);

  useEffect(() => {
    if (firstCall) {
      dispatch(onGetSegments());
      setFirstCall(false);
    }
  }, [dispatch, segments]);

  useEffect(() => {
    if (firstCall) {
      dispatch(onGetCategories());
      setFirstCall(false);
    }
  }, [dispatch, categories]);

  useEffect(() => {
    if (firstCall) {
      dispatch(onGetResources());
      setFirstCall(false);
    }
  }, [dispatch, resources]);

  useEffect(() => {
    if (firstCall) {
      dispatch(onGetReferences());
      setFirstCall(false);
    }
  }, [dispatch, references]);

  useEffect(() => {
    if (firstCall) {
      dispatch(onGetForms());
      setFirstCall(false);
    }
  }, [dispatch, references]);

  const segmentColumnNames = segments.map(segment => segment.name);
  const segmentCols = segments.map(segment => Object.assign({
    dataField: segment.name,
    text: segment.name,
    sort: true
  }));

  const referenceColumnNames = references.map(reference => reference.name);
  const referenceCols = references.map(reference => Object.assign({
      dataField: reference.name,
      text: reference.name,
      sort: true
    })
  );

  const categoryColumnNames = categories.map(category => category.name);
  const categoryCols = categories.map(category => Object.assign({
      dataField: category.name,
      text: category.name,
      sort: true
    })
  );

  const resourceColumnNames = resources.map(resource => resource.name);
  const resourceCols = resources.map(resource => Object.assign({
      dataField: resource.name,
      text: resource.name,
      sort: true
    })
  );

  useEffect(() => {
    reportsMap();
  }, [reports]);

  const reportsMap = () => {
    const segments = [];
    const categories = [];
    const resources = [];
    const references = [];

    reports.forEach(report => {
      let segment = { User: report.user.fullName };
      segmentColumnNames.forEach(segmentCol => {
        const founded = report.segmentCounts.find(item => segmentCol === item.name);
        segment = founded ? Object.assign(segment, { [segmentCol]: founded.count }) : Object.assign(segment, { [segmentCol]: 0 });
      });
      segments.push(segment);

      let category = { User: report.user.fullName };
      categoryColumnNames.forEach(categoryCol => {
        const founded = report.categoryCounts.find(item => categoryCol === item.name);
        category = founded ? Object.assign(category, { [categoryCol]: founded.count }) : Object.assign(category, { [categoryCol]: 0 });
      });
      categories.push(category);

      let resource = { User: report.user.fullName };
      resourceColumnNames.forEach(resourceCol => {
        const founded = report.resourceCount.find(item => resourceCol === item.name);
        resource = founded ? Object.assign(resource, { [resourceCol]: founded.count }) : Object.assign(resource, { [resourceCol]: 0 });
      });
      resources.push(resource);

      let reference = { User: report.user.fullName };
      referenceColumnNames.forEach(referenceCol => {
        const founded = report.referenceCount.find(item => referenceCol === item.name);
        reference = founded ? Object.assign(reference, { [referenceCol]: founded.count }) : Object.assign(reference, { [referenceCol]: 0 });
      });
      references.push(reference);

    });
    setResourceData(resources);
    setReferenceData(references);
    setSegmentData(segments);
    setCategoryData(categories);
  };

  useEffect(() => {
    resourceCols.unshift({
      dataField: "User",
      text: `${props.t("User")}`,
      sort: true
    });
    segmentCols.unshift({
      dataField: "User",
      text: `${props.t("User")}`,
      sort: true
    });
    categoryCols.unshift({
      dataField: "User",
      text: `${props.t("User")}`,
      sort: true
    });
    referenceCols.unshift({
      dataField: "User",
      text: `${props.t("User")}`,
      sort: true
    });
  }, [reportsMap]);

  const handleFilteredCustomerData = () => {
    post(url.LEAD_REPORTS_BY_DATE_CRITERIA, body).then(response => setReports(response.data));
  };

  const handleClearFilterInputs = () => {
    setFilterCreateDateStart("");
    setFilterCreateDateEnd("");
    setFilterUpdateDateStart("");
    setFilterUpdateDateEnd("");
    setFilterCategory("");
    setFilterFacebookForm("");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={props.t("User Reports")} />
          <div className="row">
            <div className="col-10">
              <div className="row">
                <div className="mb-3 col-2">
                  <Label className="form-label">{props.t("Create Date Start")}</Label>
                  <Input
                    id="create-date-start"
                    name="create-date-start"
                    type="date"
                    onChange={e => handleFilterCreateDateStart(e)}
                    value={filterCreateDateStart}
                  />
                </div>
                <div className="mb-3 col-2">
                  <Label className="form-label">{props.t("Create Date End")}</Label>
                  <Input
                    id="create-date-end"
                    name="create-date-end"
                    type="date"
                    onChange={e => handleFilterCreateDateEnd(e)}
                    value={filterCreateDateEnd}
                  />
                </div>
                <div className="mb-3 col-2">
                  <Label className="form-label">{props.t("Update Date Start")}</Label>
                  <Input
                    id="update-date-start"
                    name="update-date-start"
                    type="date"
                    onChange={e => handleFilterUpdateDateStart(e)}
                    value={filterUpdateDateStart}
                  />
                </div>
                <div className="mb-3 col-2">
                  <Label className="form-label">{props.t("Update Date End")}</Label>
                  <Input
                    id="update-date-end"
                    name="update-date-end"
                    type="date"
                    onChange={e => handleFilterUpdateDateEnd(e)}
                    value={filterUpdateDateEnd}
                  />
                </div>
                <div className="mb-3 col-2">
                  <Label className="form-label">{props.t("Form Name")}</Label>
                  <Select
                    value={filterFacebookForm}
                    onChange={(event) => {
                      handleFilterFacebookForm(event);
                    }}
                    options={formNameOptions}
                    className="select2-selection"
                  />
                </div>
                <div className="mb-3 col-2">
                  <Label className="form-label">{props.t("Category")}</Label>
                  <Select
                    value={filterCategory}
                    onChange={(event) => {
                      handleFilterCategory(event);
                    }}
                    options={categoryOptions}
                    className="select2-selection"
                  />
                </div>
              </div>
            </div>
            <div className="col-2 mt-4">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-0"
                onClick={() => handleFilteredCustomerData()}
              >
                <i className="mdi mdi-search-web me-1" />
                {props.t("Filter")}
              </Button>
              <Button
                type="button"
                color="warning"
                className="btn-rounded mb-0"
                onClick={() => handleClearFilterInputs()}
              >
                <i className="mdi mdi-format-clear me-1" />
                {props.t("Clear All Filters")}
              </Button>
            </div>
          </div>
          {segments.length > 0 &&
            <div className="table-responsive table">
              <CustomDataTable
                title={props.t("Segments")}
                columns={segmentCols}
                data={segmentData}
                selectRow={false}
                export={!roleUser}
              />
            </div>
          }
          {categories.length > 0 &&
            <div className="table-responsive table">
              <CustomDataTable
                title={props.t("Categories")}
                columns={categoryCols}
                data={categoryData}
                selectRow={false}
                export={!roleUser}
              />
            </div>
          }
          {references.length > 0 &&
            <div className="table-responsive table">
              <CustomDataTable
                title={props.t("References")}
                columns={referenceCols}
                data={referenceData}
                selectRow={false}
                export={!roleUser}
              />
            </div>
          }
          {resources.length > 0 &&
            <div className="table-responsive table">
              <CustomDataTable
                title={props.t("Resources")}
                columns={resourceCols}
                data={resourceData}
                selectRow={false}
                export={!roleUser}
              />
            </div>
          }
        </Container>
      </div>
    </React.Fragment>
  );
};
Report.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(Report);