import React, { useEffect, useMemo, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../components/Common/DeleteModal";
import {
  addNewHospital as onAddNewHospital,
  deleteHospital as onDeleteHospital,
  getHospitals as onGetHospitals,
  updateHospital as onUpdateHospital
} from "store/hospital/actions";

//redux
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../components/Common/TableContainer";

// Column
import { Address, Description, Name, Phone } from "./hospitalCol";
import { withTranslation } from "react-i18next";
import Notification from "../../components/Notification";

const Hospital = props => {

  //meta title
  document.title = `CRM | ${props.t("Hospitals")}`;

  const dispatch = useDispatch();

  const { hospitals, error } = useSelector(state => ({
    hospitals: state.Hospital.hospitals,
    error: state.Hospital.error
  }));

  const [modal, setModal] = useState(false);
  const [hospitalList, setHospitalList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [hospital, setHospital] = useState(null);
  const [firstCall, setFirstCall] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");

  // validation
  const validation = {

    values: {
      name: (hospital && hospital.name) || "",
      phoneNumber: (hospital && hospital.phoneNumber) || "",
      description: (hospital && hospital.description) || "",
      address: (hospital && hospital.address) || ""
    },
    handleSubmit: (values) => {
      if (isEdit) {
        const updateHospital = {
          id: hospital ? hospital.id : 0,
          name: values.name,
          phoneNumber: (hospital && hospital.phoneNumber) || "",
          description: (hospital && hospital.description) || "",
          address: (hospital && hospital.address) || ""
        };
        // update hospital
        dispatch(onUpdateHospital(updateHospital));
      } else {
        const newHospital = {
          name: values["name"],
          phoneNumber: values["phoneNumber"],
          description: values["description"],
          address: values["address"]
        };
        // save new hospital
        dispatch(onAddNewHospital(newHospital));
      }
      toggle();
    }
  };

  const handleHospitalClick = arg => {
    const hospital = arg;

    setHospital({
      id: hospital.id,
      name: hospital.name,
      phoneNumber: hospital.phoneNumber,
      description: hospital.description,
      address: hospital.address
    });

    setIsEdit(true);
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        HeaderLabel: `${props.t("Name")}`,
        Header: "İsim",
        accessor: "name",
        filterable: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Phone")}`,
        Header: "Phone",
        accessor: "phoneNumber",
        filterable: true,
        Cell: (cellProps) => {
          return <Phone {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Description")}`,
        Header: "Açıklama",
        accessor: "description",
        filterable: true,
        Cell: (cellProps) => {
          return <Description {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Address")}`,
        Header: "Address",
        accessor: "address",
        filterable: true,
        Cell: (cellProps) => {
          return <Address {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("isDeletable")}`,
        Header: "Deletable",
        accessor: "deletable",
        filterable: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              {props.t(`${cellProps.row.original.deletable === false ? "Not Deletable" : "Deletable"}`)}
            </div>
          );
        }
      },
      {
        HeaderLabel: `${props.t("Action")}`,
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const hospitalData = cellProps.row.original;
                  handleHospitalClick(hospitalData);
                }
                }
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {props.t("Edit")}
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const hospitalData = cellProps.row.original;
                  onClickDelete(hospitalData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      }
    ],
    []
  );

  const handleChange = (e) => {
    setHospital({
      ...hospital,
      [e.target.name]: e.target.value
    });
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setHospital(null);
    } else {
      setModal(true);
    }
  };

  //delete hospital
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (hospital) => {
    setHospital(hospital);
    setDeleteModal(true);
  };

  const handleDeleteHospital = () => {
    if (hospital.id) {
      dispatch(onDeleteHospital(hospital.id));
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    if (firstCall) {
      dispatch(onGetHospitals());
      setFirstCall(false);
    }
  }, [dispatch, hospitals]);

  useEffect(() => {
    setHospitalList(hospitals);
  }, [hospitals]);

  useEffect(() => {
    if (!isEmpty(hospitals)) {
      setHospitalList(hospitals);
    }
  }, [hospitals]);

  const handleHospitalClicks = () => {
    setHospitalList("");
    setIsEdit(false);
    toggle();
  };

  const handleRefresh = () => {
    dispatch(onGetHospitals());
  };

  const handleClose = () => {
    setMessage("");
    setShowNotification(false);
  };

  useEffect(() => {
    if (error !== undefined && error.data !== undefined) {
      setMessage(error.data.message);
      setShowNotification(true);
      setNotificationType("Danger");
    }
  }, [error]);

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteHospital}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Notification
            message={message}
            show={showNotification}
            callbackShow={handleClose}
            type={notificationType}
          />
          <Breadcrumbs title={props.t("Hospitals")} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    refreshButton={true}
                    handleRefreshClick={handleRefresh}
                    columns={columns}
                    data={hospitals}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    handleAddClick={handleHospitalClicks}
                    customPageSize={25}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit
                        ? `${props.t("Edit")}`
                        : `${props.t("Add")}`}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit(validation.values);
                          return false;
                        }}
                      >
                        <Row form="true">
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Name")}</Label>
                              <Input
                                name="name"
                                type="text"
                                onChange={handleChange}
                                value={validation.values.name || ""}
                                invalid={!validation.values.name}
                                required={true}
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Phone")}</Label>
                              <Input
                                name="phoneNumber"
                                type="text"
                                onChange={handleChange}
                                value={validation.values.phoneNumber || ""}
                                invalid={!validation.values.phoneNumber}
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Description")}</Label>
                              <Input
                                name="description"
                                type="text"
                                onChange={handleChange}
                                value={validation.values.description || ""}
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Address")}</Label>
                              <Input
                                name="address"
                                type="text"
                                onChange={handleChange}
                                value={validation.values.address || ""}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-customer"
                              >
                                {props.t("Save")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
Hospital.propTypes = {
  hospitals: PropTypes.array,
  onGetHospitals: PropTypes.func,
  onAddNewHospital: PropTypes.func,
  onDeleteHospital: PropTypes.func,
  onUpdateHospital: PropTypes.func
};

export default withRouter(withTranslation()(Hospital));
