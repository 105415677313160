import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Col, Input, Label, Row } from "reactstrap";

import { withTranslation } from "react-i18next";
import { CreateDate, ReminderDesc } from "../CustCol";
import { formatDate } from "../../../common/commonFunctions";
import { get } from "../../../helpers/axios_with_headers";
import * as url from "../../../helpers/url_helper";
import CustomDatePicker from "../../../components/Common/CustomDatePicker";
import BasicTable from "../../../components/Common/BasicTable";
import { addNewReminder } from "../../../store/reminder/actions";
import { useDispatch } from "react-redux";

const CustReminder = props => {
  const dispatch = useDispatch();

  const [reminderDate, setReminderDate] = useState(null);
  const [reminderDescription, setReminderDescription] = useState("");
  const [selectedReminder, setSelectedReminder] = useState(false);
  const [custReminder, setCustReminder] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [customer, setCustomer] = useState({});

  const reminderColumns = useMemo(
    () => [
      {
        HeaderLabel: `${props.t("Date")}`,
        Header: "Tarih",
        accessor: "reminderDate",
        filterable: true,
        className: "w-25",
        Cell: (cellProps) => {
          return <CreateDate {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Description")}`,
        Header: "Açıklama",
        accessor: "description",
        filterable: true,
        className: "w-25",
        Cell: (cellProps) => {
          return <ReminderDesc {...cellProps} />;
        }
      }

    ],
    []
  );

  useEffect(() => {
    setCustomer(props.customer);
  }, [props.customer]);

  const saveReminder = (e) => {
    const createReminder = {
      reminderDate: formatDate(reminderDate),
      description: reminderDescription,
      lead: props.customer
    };

    dispatch(addNewReminder(createReminder));
    setForceUpdate(true);
    setReminderDate(null);
    setReminderDescription("");
    setSelectedReminder(false);
  };

  useEffect(() => {
    if (forceUpdate && customer.id || customer.id) {
      get(`${url.GET_LEAD_REMINDER}/${customer.id}`).then(res => setCustReminder(res.data));
      setForceUpdate(false);
    }
  }, [forceUpdate, customer.id]);

  const handleChangeReminderDate = (e) => {
    setSelectedReminder(true);
    setReminderDate(e);
  };

  const handleChangeReminderDescription = (e) => {
    setReminderDescription(e.target.value);
  };

  return (
    <React.Fragment>
      <div className="row mt-3">
        <div className="col-10">
          <div className="row">
            <div className="col-xs-12 col-md-4">
              <label>{props.t("Reminder Time")}</label>
              <CustomDatePicker
                id="reminderDate"
                selected={reminderDate}
                callback={handleChangeReminderDate}
                showTimeSelect={true}
                showTimeSelectOnly={false}
              />
            </div>
            <div className="col-xs-12 col-md-8">
              <label>{props.t("Description")}</label>
              <Input
                id="reminderDesc"
                value={reminderDescription}
                onChange={handleChangeReminderDescription}
              />
            </div>
          </div>
        </div>
        <div className="col-2">
          <div className="mt-5">
            <Button
              id="saveReminder"
              type="button"
              color="success"
              onClick={saveReminder}
              disabled={!selectedReminder}
            >
              {props.t("Add")}
            </Button>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        {custReminder.length > 0 ? (
          <BasicTable
            id="custReminder"
            customPageSize={10}
            columns={reminderColumns}
            data={custReminder}
            className="custom-header-basic-css"
          />) : null}
      </div>
    </React.Fragment>
  );
};
CustReminder.propTypes = {
  customer: PropTypes.any
};

export default withTranslation()(CustReminder);
