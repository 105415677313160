import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, CardBody, Container, Input, Label, Row } from "reactstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import CustomDataTable from "../../components/Common/CustomDataTable";
import { userCriteria } from "../../store/contacts/actions";
import { getAuthLogs } from "../../store/authLog/actions";
import Select from "react-select";

const UserLoginHistory = (props) => {
  const dispatch = useDispatch();

  const [userLoginData, setUserLoginData] = useState([]);
  const [filterLoginDateStart, setFilterLoginDateStart] = useState();
  const [filterLoginDateEnd, setFilterLoginDateEnd] = useState();
  const [user, setUser] = useState();
  const [status, setStatus] = useState();

  let body = { size: 100, page: 0 };

  const statusOptions = [
    { label: `${props.t("SUCCESS")}`, value: "SUCCESS" },
    { label: `${props.t("FAIL")}`, value: "FAIL" }
  ];

  useEffect(() => {
    let userReq = Object.assign({ notRole: "ROLE_MODERATOR" });
    dispatch(userCriteria(userReq));
  }, []);

  const { authLogCriteria } = useSelector(state => ({
    authLogCriteria: state.authLog.authLogCriteria
  }));

  useEffect(() => {
    if (authLogCriteria !== undefined && authLogCriteria.content !== undefined) {
      setUserLoginData(authLogCriteria.content);
    }
  }, [authLogCriteria]);

  const { users } = useSelector(state => ({
    users: state.contacts.users
  }));

  const userOptions = [];
  users.map((item) => {
    userOptions.push({ label: item.fullName, value: item });
  });

  useEffect(() => {
    dispatch(getAuthLogs(body));
  }, []);

  function handleFilterLoginDateStart(e) {
    setFilterLoginDateStart(e.target.value);
  }

  function handleFilterLoginDateEnd(e) {
    setFilterLoginDateEnd(e.target.value);
  }

  const onChangeSelect = (e) => {
    setStatus(e);
  };

  const onChangeUser = (e) => {
    setUser(e);
  };

  const getFilteredDatas = () => {
    if (user && user.value !== undefined && user.value.id !== null) {
      body = { ...body, "loggedInUser": user.value.id };
    }
    if (filterLoginDateStart !== "" && filterLoginDateStart !== undefined) {
      body = { ...body, "loginDateStart": filterLoginDateStart };
    }
    if (filterLoginDateEnd !== "" && filterLoginDateEnd !== undefined) {
      body = { ...body, "loginDateEnd": filterLoginDateEnd };
    }
    if (status !== undefined && status.value !== undefined) {
      body = { ...body, "status": status.value };
    }

    dispatch(getAuthLogs(body));
  };


  const userLoginHistoryColumns = useMemo(
    () => [
      {
        dataField: "loggedInUser.fullName",
        text: `${props.t("User")}`,
        sort: true
      },
      {
        dataField: "loggedInUser.username",
        text: `${props.t("Username")}`,
        sort: true
      },
      {
        dataField: "loginDate",
        text: `${props.t("Login Date")}`,
        sort: true
      },
      {
        dataField: "ipAddress",
        text: `${props.t("IP")}`,
        sort: true
      },
      {
        dataField: "status",
        text: `${props.t("Status")}`,
        sort: true
      },
      {
        dataField: "authType",
        text: `${props.t("Type")}`,
        sort: true
      },
      {
        dataField: "description",
        text: `${props.t("Description")}`,
        sort: true
      }
    ],
    []
  );

  return (
    <div className="page-content-full">
      <Container fluid >
        <Breadcrumbs title={props.t("User Login History")} />
        <Row className="m-3">
          <div className="col-xs-6 col-md-3 mt-3">
            <Label className="form-label">{props.t("User")}</Label>
            <Select
              id="user"
              clasName="bs-select"
              value={user}
              onChange={(e) => onChangeUser(e)}
              options={userOptions}
              isClearable={true}
            />
          </div>
          <div className="col-xs-6 col-md-3 mt-3">
            <Label className="form-label">{props.t("Login Date Start")}</Label>
            <Input
              id="start-date"
              name="start-date"
              type="date"
              onChange={e => handleFilterLoginDateStart(e)}
              value={filterLoginDateStart}
            />
          </div>
          <div className="col-xs-6 col-md-3 mt-3">
            <Label className="form-label">{props.t("Login Date End")}</Label>
            <Input
              id="end-date"
              name="end-date"
              type="date"
              onChange={e => handleFilterLoginDateEnd(e)}
              value={filterLoginDateEnd}
            />
          </div>
          <div className="col-xs-6 col-md-2 mt-3">
            <Label className="form-label">{props.t("Status")}</Label>
            <Select
              id="user-history-status"
              value={status}
              onChange={(e) => {
                onChangeSelect(e);
              }}
              options={statusOptions}
            />
          </div>
          <div className="col-xs-6 col-md-1 mt-3">
            <Button
              type="button"
              color="success"
              className="btn-rounded mb-0 mt-4"
              onClick={() => getFilteredDatas()}
            >
              <i className="mdi mdi-search-web me-1" />
              {props.t("Get")}
            </Button>
          </div>
        </Row>
        <br />
        <Card className="shadow-none overflow-hidden">
          <CardBody className="m-3">
            {userLoginData.length > 0 ? (
              <CustomDataTable
                className="table-responsive table-light"
                id="auth-log-history"
                title={props.t("User Login History")}
                columns={userLoginHistoryColumns}
                data={userLoginData}
                selectRow={false}
                export={false}
              />
            ) : null}
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};
UserLoginHistory.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(UserLoginHistory);