import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../../store/contacts/actions";
import { Alert, Col, Input, Modal, ModalBody, ModalHeader, Row,Label } from "reactstrap";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Notification from "../Notification";
import { logoutUser } from "../../store/auth/login/actions";

const ChangePassword = props => {

  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const [passwordRetype, setPasswordRetype] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");
  const [passValid, setPassValid] = useState(false);
  const [alert, setAlert] = useState(false);
  const [modal, setModal] = useState(false);
  const [user, setUser] = useState(props.user);

  const handleChangePass = (e) => {
    const regEXp = /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-99!@#$%^&*.]{8,99}$/;
    const trimmedPass = e.target.value.trim();
    setPassword(trimmedPass);
    setAlert(true);
    if (regEXp.test(trimmedPass)) {
      setPassValid(true);
      setAlert(false);
      setUser({
        ...user,
        ["password"]: trimmedPass
      });
    }
  };

  useEffect(()=>{
    toggleChangePassword();
  },[props.modal])

  const handleChangePassRetype = (e) => {
    setPasswordRetype(e.target.value.trim());
  };

  const toggleChangePassword = () => {
    if (modal) {
      setModal(false);
      setPassword("");
      setPasswordRetype("");
      props.callbackModalResponse(false);
    } else {
      setModal(true);
    }
  };

  const { changePassSuccess } = useSelector(state => ({
    changePassSuccess: state.contacts.changePassSuccess
  }));

  const saveChanges = () => {
    if (!passValid || user.password !== passwordRetype) {
      setMessage(`${props.t("Passwords must match and contain numbers and letters and must be 8 characters long may include (!@#$%^&*.)")}`);
      setShowNotification(true);
      setNotificationType("Warning");
    } else {
      dispatch(changePassword(user));
    }
  };

  useEffect(()=>{
    if (changePassSuccess === true){
      toggleChangePassword();
    }
  },[changePassSuccess])

  const handleClose = () => {
    setMessage("");
    setShowNotification(false);
  };

  return (
    <React.Fragment>
      <Notification
        message={message}
        show={showNotification}
        callbackShow={handleClose}
        type={notificationType}
      />
      <Modal isOpen={modal} toggle={toggleChangePassword}>
        <ModalHeader toggle={toggleChangePassword} tag="h4">
          {props.t("Change Password")}
        </ModalHeader>
        <ModalBody>
          <Row form="true">
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">{props.t("Password")}</Label>
                {alert === true ? (<Alert color="warning" style={{ marginTop: "3px" }}>
                  {props.t("Password must contain numbers and letters and must be 8 characters long may include (!@#$%^&*.)")}
                </Alert>) : null}
                <Input
                  name="password"
                  type="password"
                  value={password}
                  onChange={handleChangePass}
                  invalid={!passValid}
                />
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Password Retype")}</Label>
                {passwordRetype !== password ? (<Alert color="warning" style={{ marginTop: "3px" }}>
                  {props.t("Passwords do not match!!!")}
                </Alert>) : null}
                <Input
                  name="passwordReType"
                  type="password"
                  value={passwordRetype}
                  onChange={handleChangePassRetype}
                  invalid={passwordRetype !== password}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  type="submit"
                  className="btn btn-success save-customer"
                  onClick={saveChanges}
                >
                  {props.t("Save")}
                </button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};


ChangePassword.propTypes = {
  t: PropTypes.any,
  user: PropTypes.any,
  modal: PropTypes.bool,
  callbackModalResponse: PropTypes.func
};

export default withRouter(withTranslation()(ChangePassword));