import { call, put, takeEvery } from "redux-saga/effects";

// Customers Redux States
import {
  ADD_NEW_CUSTOMER,
  ASSIGN_REPRESENTATIVE,
  ASSIGN_SEGMENT,
  DELETE_CUSTOMER,
  GET_CUSTOMER_BY_ID,
  GET_CUSTOMERS,
  LEAD_CRITERIA,
  TRANSFER_LEAD_DATA,
  UPDATE_CUSTOMER
} from "./actionTypes";

import {
  addCustomerFail,
  addCustomerSuccess,
  assignRepresentativeFail,
  assignRepresentativeSuccess,
  assignSegmentFail,
  assignSegmentSuccess,
  customerCriteriaFail,
  customerCriteriaSuccess,
  deleteCustomerFail,
  deleteCustomerSuccess,
  getCustomerByIdFail,
  getCustomerByIdSuccess,
  getCustomersFail,
  getCustomersSuccess,
  transferDataFail,
  transferDataSuccess,
  updateCustomerFail,
  updateCustomerSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { del, get, post } from "../../helpers/api_helper";

function* fetchCustomers() {
  try {
    const response = yield call(getCustomers);
    yield put(getCustomersSuccess(response));
  } catch (error) {
    yield put(getCustomersFail(error));
  }
}

const getCustomers = () => get(url.GET_CUSTOMERS);

function* onUpdateCustomer({ payload: customer }) {
  try {
    const response = yield call(updateCustomer, customer);
    yield put(updateCustomerSuccess(response));
  } catch (error) {
    yield put(updateCustomerFail(error));
  }
}

const updateCustomer = (customer) => post(url.UPDATE_CUSTOMER, customer);

function* onDeleteCustomer({ payload: customer }) {
  try {
    const response = yield call(deleteCustomer, customer);
    if (response.length > 0) {
      yield put(deleteCustomerSuccess(response));
    } else {
      yield put(deleteCustomerSuccess(customer));
    }
  } catch (error) {
    yield put(deleteCustomerFail(error));
  }
}

const deleteCustomer = (customer) => del(url.DELETE_CUSTOMER, customer);

function* onAddNewCustomer({ payload: customer }) {
  try {
    const response = yield call(addNewCustomer, customer);
    if (response.status && response.status !== 200) {
      yield put(addCustomerFail(response));
    } else {
      yield put(addCustomerSuccess(response));
    }
  } catch (error) {
    yield put(addCustomerFail(error));
  }
}

const addNewCustomer = (customer) => post(url.ADD_NEW_CUSTOMER, customer);

function* onGetCustomer({ id }) {
  try {
    const response = yield call(getCustomerById, id);
    yield put(getCustomerByIdSuccess(response));
  } catch (error) {
    yield put(getCustomerByIdFail(error));
  }
}

const getCustomerById = (id) => get(`${url.GET_CUSTOMER_BY_ID}/${id}`);

function* onAssignRepresentative({ payload: request }) {
  try {
    const response = yield call(assignRepresent, request);
    yield put(assignRepresentativeSuccess(response));
  } catch (error) {
    yield put(assignRepresentativeFail(error));
  }
}

const assignRepresent = (req) => post(url.SET_REPRESENTATIVE, req);

function* onAssignSegment({ payload: request }) {
  try {
    const response = yield call(assignSegment, request);
    yield put(assignSegmentSuccess(response));
  } catch (error) {
    yield put(assignSegmentFail(error));
  }
}

const assignSegment = (req) => post(url.SET_SEGMENT, req);

function* fetchLeadCriteria({ payload: req }) {

  try {
    const response = yield call(getLeadCriteria, req);
    yield put(customerCriteriaSuccess(response, req));
  } catch (error) {
    yield put(customerCriteriaFail(error));
  }
}

const getLeadCriteria = (req) => post(url.LEADS_CRITERIA, req);

function* fetchTransferData({ payload: req }) {
  try {
    const response = yield call(sendTransferData, req);
    yield put(transferDataSuccess(response, req));
  } catch (error) {
    yield put(transferDataFail(error));
  }
}

const sendTransferData = (req) => post(url.TRANSFER_DATA, req);

function* customersSaga() {
  yield takeEvery(GET_CUSTOMERS, fetchCustomers);
  yield takeEvery(ADD_NEW_CUSTOMER, onAddNewCustomer);
  yield takeEvery(UPDATE_CUSTOMER, onUpdateCustomer);
  yield takeEvery(DELETE_CUSTOMER, onDeleteCustomer);
  yield takeEvery(GET_CUSTOMER_BY_ID, onGetCustomer);
  yield takeEvery(ASSIGN_REPRESENTATIVE, onAssignRepresentative);
  yield takeEvery(ASSIGN_SEGMENT, onAssignSegment);
  yield takeEvery(LEAD_CRITERIA, fetchLeadCriteria);
  yield takeEvery(TRANSFER_LEAD_DATA, fetchTransferData);
}

export default customersSaga;
