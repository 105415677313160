
/* TERMS */
export const GET_USER_TERMS = "GET_USER_TERMS"
export const GET_USER_TERMS_SUCCESS = "GET_USER_TERMS_SUCCESS"
export const GET_USER_TERMS_FAIL = "GET_USER_TERMS_FAIL"

/**
 * POST TERMS
 */
export const POST_USER_TERMS = "POST_USER_TERMS"
export const POST_USER_TERMS_SUCCESS = "POST_USER_TERMS_SUCCESS"
export const POST_USER_TERMS_FAIL = "POST_USER_TERMS_FAIL"

/**
 * POLICY
 */
export const GET_USER_POLICY = "GET_USER_POLICY"
export const GET_USER_POLICY_SUCCESS = "GET_USER_POLICY_SUCCESS"
export const GET_USER_POLICY_FAIL = "GET_USER_POLICY_FAIL"

/**
 * POST POLICY
 */
export const POST_USER_POLICY = "POST_USER_POLICY"
export const POST_USER_POLICY_SUCCESS = "POST_USER_POLICY_SUCCESS"
export const POST_USER_POLICY_FAIL = "POST_USER_POLICY_FAIL"

/**
 * LATEST_CONTRACTS
 */
export const GET_LATEST_CONTRACTS = "GET_LATEST_CONTRACTS"
export const GET_LATEST_CONTRACTS_SUCCESS = "GET_LATEST_CONTRACTS_SUCCESS"
export const GET_LATEST_CONTRACTS_FAIL = "GET_LATEST_CONTRACTS_FAIL"
