import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//Import mini card widgets
//Import Images
// import charts
import { getLanguages as onGetLanguages, getUserProfile, updateUser as onUpdateUser } from "store/actions";
import { Phone } from "../../Customers/CustCol";
import { withTranslation } from "react-i18next";
import ActivityComp from "../../Dashboard/ActivityComp";
import WelcomeComp from "../../Dashboard/WelcomeComp";
import { fileToBase64 } from "../../../common/commonFunctions";
import Select from "react-select";

const ContactsProfile = props => {

  //meta title
  document.title = `${props.t("User Profile")}`;

  const { userProfile, onGetUserProfile } = props;
  const [selectedMulti, setselectedMulti] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    getUserProfile();
  }, [onGetUserProfile]);

  const initalCont = {
    id: userProfile.id,
    username: userProfile.username,
    firstName: userProfile.firstName,
    lastName: userProfile.lastName,
    email: userProfile.email,
    photo: userProfile.photo,
    languages: userProfile.languages
  };

  const [modal, setModal] = useState(false);
  const [contact, setContact] = useState(initalCont);

  const { error } = useSelector(state => ({
    error: state.contacts.error
  }));

  const handleChange = (e) => {
    setContact({
      ...contact,
      [e.target.name]: e.target.value
    });
  };

  const handleChangePhoto = (e) => {
    fileToBase64(e.target.files[0]).then(response => {
      setContact({
        ...contact,
        ["photo"]: response
      });
    });

  };

  useEffect(() => {
    const langs = contact.languages !== null && contact.languages !== undefined ? contact.languages.map(lang => ({
      label: lang.name,
      value: lang
    })) : null;
    setselectedMulti(langs);
  }, [contact]);

  function handleMulti(selectedMulti) {
    const langCodes = selectedMulti.map((lang) => lang.value);
    setselectedMulti(selectedMulti);
    setContact({
      ...contact,
      ["languages"]: langCodes
    });
  }

  const { languages } = useSelector(state => ({
    languages: state.Languages.languages
  }));

  const languageOptions = [];
  languages.map((item) => {
    languageOptions.push({ label: item.name, value: item });
  });

  useEffect(() => {
    dispatch(onGetLanguages());
  }, []);


  const saveUser = () => {
    const updated = dispatch(onUpdateUser(contact));
    setContact(updated.payload);
    toggle();
  };

  const onRefresh = ()=>{
    dispatch(getUserProfile(contact.id));
  }

  const toggle = () => {
    if (modal) {
      setContact(initalCont);
      setModal(false);
    } else {
      setModal(true);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={props.t("User Profile")} />

          <Row>
            <Col xl={4}>
              <Card className="overflow-hidden">
                <WelcomeComp
                  viewProfileButton={false}
                />
              </Card>
            </Col>
            <Col xl={8}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    <Row className="m-2">
                      <Col xs={10}>
                        {props.t("Personal Information")}
                      </Col>
                      <Col xs={2}>
                        <Button
                          size="sm"
                          className="mb-2 me-0"
                          color="primary"
                          onClick={toggle}
                        >
                          {props.t("Edit")}
                        </Button>
                        {' '}
                        <Button
                          size="sm"
                          type="button"
                          color="success"
                          className="btn-rounded mb-2 me-0"
                          onClick={onRefresh}
                        >
                          <i className="mdi mdi-refresh" />
                        </Button>
                      </Col>
                    </Row>
                  </CardTitle>
                  {contact && (
                    <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                        <tr>
                          <th scope="row">{props.t("Username")}:</th>
                          <td>{userProfile.username}</td>
                        </tr>
                        <tr>
                          <th scope="row">{props.t("Fullname")}:</th>
                          <td>{userProfile.fullName}</td>
                        </tr>
                        <tr>
                          <th scope="row">{props.t("Phone")}:</th>
                          <td>{userProfile.phone}</td>
                        </tr>
                        <tr>
                          <th scope="row">{props.t("E-mail")}:</th>
                          <td>{userProfile.email}</td>
                        </tr>
                        <tr>
                          <th scope="row">{props.t("Languages")}:</th>
                          <td>{userProfile.languages && userProfile.languages.map(item => `${item.name} ,`)}</td>
                        </tr>
                        </tbody>
                      </Table>
                    </div>
                  )}
                </CardBody>
              </Card>
              <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle} tag="h4">{props.t("Edit")}</ModalHeader>
                <ModalBody>
                  <Row form="true">
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("Username")}</Label>
                        <Input
                          name="username"
                          type="text"
                          onChange={handleChange}
                          value={contact.username || ""}
                          invalid={!contact.username}
                        />
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("Firstname")}</Label>
                        <Input
                          name="firstName"
                          label="İsim"
                          type="text"
                          onChange={handleChange}
                          value={contact.firstName || ""}
                        />
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("Lastname")}</Label>
                        <Input
                          name="lastName"
                          label="Soyisim"
                          type="text"
                          onChange={handleChange}
                          value={contact.lastName || ""}
                        />
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("Email")}</Label>
                        <Input
                          name="email"
                          label="Email"
                          type="email"
                          onChange={handleChange}
                          value={contact.email || ""}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="control-label">
                          {props.t("Languages")}
                        </label>
                        <Select
                          value={selectedMulti}
                          isMulti={true}
                          onChange={(event) => {
                            handleMulti(event);
                          }}
                          placeholder={props.t("Select")}
                          options={languageOptions}
                          className="select2-selection"
                        />
                      </div>
                      {/*  <div className="mb-3">
                        <Label className="form-label">{props.t("Password")}</Label>
                        {alert === true ? (<Alert color="warning" style={{ marginTop: "3px" }}>
                          {props.t("Password must contain numbers and letters and must be 8 characters long may include (!@#$%^&*.)")}
                        </Alert>) : null}
                        <Input
                          name="password"
                          type="password"
                          value={password}
                          onChange={handleChangePass}
                          invalid={!passValid}
                        />
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">{props.t("Password Retype")}</Label>
                        {passwordRetype !== contact.password ? (
                          <Alert color="warning" style={{ marginTop: "3px" }}>
                            {props.t("Passwords do not match!!!")}
                          </Alert>) : null}
                        <Input
                          name="passwordReType"
                          type="password"
                          value={passwordRetype}
                          onChange={handleChangePassRetype}
                          invalid={passwordRetype !== contact.password}
                        />
                      </div>*/}
                      <div className="mb-3">
                        <Label className="form-label">{props.t("Photo")}</Label>
                        <Input
                          id="photo"
                          name="photo"
                          type="file"
                          placeholder={props.t("No file selected.")}
                          onChange={handleChangePhoto}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                          onClick={saveUser}
                        >
                          {props.t("Save")}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              {/*        <Row>
                {map(miniCards, (card, key) => (
                  <MiniCards
                    title={card.title}
                    text={card.text}
                    iconClass={card.iconClass}
                    key={"_card_" + key}
                  />
                ))}
              </Row>*/}
              <Card>
                <CardBody>
                  <ActivityComp viewMoreButton={false} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ContactsProfile.propTypes = {
  userProfile: PropTypes.any,
  onGetUserProfile: PropTypes.func,
  t: PropTypes.any
};

const mapStateToProps = ({ contacts }) => ({
  userProfile: contacts.userProfile
});

const mapDispatchToProps = dispatch => ({
  onGetUserProfile: () => dispatch(getUserProfile())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(ContactsProfile)));
