import {
    GET_DESCRIPTION_TEMPLATES_FAIL,
    GET_DESCRIPTION_TEMPLATES_SUCCESS,
    ADD_DESCRIPTION_TEMPLATE_SUCCESS,
    ADD_DESCRIPTION_TEMPLATE_FAIL,
    UPDATE_DESCRIPTION_TEMPLATE_SUCCESS,
    UPDATE_DESCRIPTION_TEMPLATE_FAIL,
    DELETE_DESCRIPTION_TEMPLATE_SUCCESS,
    DELETE_DESCRIPTION_TEMPLATE_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    descriptionTemplates: [],
    error: {},
};

const DescriptionTemplate = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DESCRIPTION_TEMPLATES_SUCCESS:
            return {
                ...state,
                descriptionTemplates: action.payload,
            };

        case GET_DESCRIPTION_TEMPLATES_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case ADD_DESCRIPTION_TEMPLATE_SUCCESS:
            return {
                ...state,
                descriptionTemplates: [...state.descriptionTemplates, action.payload],
            };

        case ADD_DESCRIPTION_TEMPLATE_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case UPDATE_DESCRIPTION_TEMPLATE_SUCCESS:
            return {
                ...state,
                descriptionTemplates: state.descriptionTemplates.map(descriptionTemplate =>
                    descriptionTemplate.id.toString() === action.payload.id.toString()
                        ? { descriptionTemplate, ...action.payload }
                        : descriptionTemplate
                ),
            };

        case UPDATE_DESCRIPTION_TEMPLATE_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case DELETE_DESCRIPTION_TEMPLATE_SUCCESS:
            return {
                ...state,
                descriptionTemplates: state.descriptionTemplates.filter(
                    descriptionTemplate => descriptionTemplate.id.toString() !== action.req.toString()
                ),
            };

        case DELETE_DESCRIPTION_TEMPLATE_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default DescriptionTemplate;
