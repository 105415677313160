import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../components/Common/DeleteModal";
import {
  addNewLeadIntegrationDefinition as onAddNewLeadIntegrationDefinition,
  deleteLeadIntegrationDefinition as onDeleteLeadIntegrationDefinition,
  getLeadIntegrationDefinitions as onGetLeadIntegrationDefinitions,
  updateLeadIntegrationDefinition as onUpdateLeadIntegrationDefinition
} from "store/leadIntegrationDefinition/actions";

//redux
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../components/Common/TableContainer";

// Column
import { Type, URL } from "./leadIntDefCol.js";
import { withTranslation } from "react-i18next";
import Notification from "../../components/Notification";
import Select from "react-select";

const LeadIntegrationDefinition = props => {

  //meta title
  document.title = `CRM | ${props.t("Lead Integration Definitions")}`;

  const dispatch = useDispatch();

  const { leadIntegrationDefs, error, definition } = useSelector(state => ({
    leadIntegrationDefs: state.leadIntegrationDefs.leadIntegrationDefs,
    error: state.leadIntegrationDefs.error,
    definition: state.leadIntegrationDefs.definition
  }));

  const [modal, setModal] = useState(false);
  const [leadIntegrationDefinitionList, setLeadIntegrationDefinitionList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [leadIntegrationDefinition, setLeadIntegrationDefinition] = useState(null);
  const [firstCall, setFirstCall] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");
  const [status, setStatus] = useState({ label: "", value: "" });
  const [type, setType] = useState({ label: "", value: "" });
  const [url, setUrl] = useState("");
  const [secretKey, setSecretKey] = useState("");

  const handleSubmit = () => {
    if (isEdit) {
      const updateLeadIntegrationDefinition = {
        id: leadIntegrationDefinition ? leadIntegrationDefinition.id : 0,
        type: type.value,
        status: status.value,
        url: url,
        secretKey: secretKey
      };
      dispatch(onUpdateLeadIntegrationDefinition(updateLeadIntegrationDefinition));
    } else {
      const newLeadIntegrationDefinition = {
        type: type.value,
        status: status.value,
        url: url,
        secretKey: secretKey
      };
      dispatch(onAddNewLeadIntegrationDefinition(newLeadIntegrationDefinition));
    }
  };

  useEffect(() => {
    setSecretKey(definition.secretKey);
  }, [definition.secretKey]);

  const handleLeadIntegrationDefinitionClick = arg => {
    const leadIntegrationDefinition = arg;

    setLeadIntegrationDefinition({
      id: leadIntegrationDefinition ? leadIntegrationDefinition.id : 0,
      type: leadIntegrationDefinition.type,
      status: leadIntegrationDefinition.status,
      url: leadIntegrationDefinition.url,
      secretKey: leadIntegrationDefinition.secretKey
    });

    setSecretKey(leadIntegrationDefinition.secretKey);
    setStatus({ label: `${props.t(leadIntegrationDefinition.status)}`, value: leadIntegrationDefinition.status });
    setType({ label: `${props.t(leadIntegrationDefinition.type)}`, value: leadIntegrationDefinition.type });
    setUrl(leadIntegrationDefinition.url);

    setIsEdit(true);
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        HeaderLabel: `${props.t("Integration Type")}`,
        Header: "Integration Type",
        accessor: "typeLabel",
        filterable: true,
        Cell: (cellProps) => {
          return <Type {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("URL")}`,
        Header: "URL",
        accessor: "url",
        filterable: true,
        Cell: (cellProps) => {
          return <URL {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("isDeletable")}`,
        Header: "Deletable",
        accessor: "deletable",
        filterable: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              {props.t(`${cellProps.row.original.deletable === false ? "Not Deletable" : "Deletable"}`)}
            </div>
          );
        }
      },
      {
        HeaderLabel: `${props.t("Action")}`,
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const leadIntegrationDefinitionData = cellProps.row.original;
                  handleLeadIntegrationDefinitionClick(leadIntegrationDefinitionData);
                }
                }
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {props.t("Edit")}
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const leadIntegrationDefinitionData = cellProps.row.original;
                  onClickDelete(leadIntegrationDefinitionData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      }
    ],
    []
  );

  const toggle = () => {
    if (modal) {
      setModal(false);
      setLeadIntegrationDefinition(null);
      setSecretKey("");
      setUrl("");
      setType("");
      setStatus("");
    } else {
      setModal(true);
    }
  };

  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (leadIntegrationDefinition) => {
    setLeadIntegrationDefinition(leadIntegrationDefinition);
    setDeleteModal(true);
  };

  const handleDeleteLeadIntegrationDefinition = () => {
    if (leadIntegrationDefinition.id) {
      dispatch(onDeleteLeadIntegrationDefinition(leadIntegrationDefinition.id));
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    if (firstCall) {
      dispatch(onGetLeadIntegrationDefinitions());
      setFirstCall(false);
    }
  }, [dispatch, leadIntegrationDefs]);

  useEffect(() => {
    const leadIntegrationDefMap = [];
    leadIntegrationDefs.forEach(def => {
      leadIntegrationDefMap.push(Object.assign(def, {
        typeLabel: props.t(`${def.type}`)
      }));
    });
    setLeadIntegrationDefinitionList(leadIntegrationDefMap);
  }, [leadIntegrationDefs]);


  const handleLeadIntegrationDefinitionClicks = () => {
    setIsEdit(false);
    toggle();
  };

  const handleRefresh = () => {
    dispatch(onGetLeadIntegrationDefinitions());
  };

  const handleClose = () => {
    setMessage("");
    setShowNotification(false);
  };

  useEffect(() => {
    if (error !== undefined && error.data !== undefined) {
      setMessage(error.data.message);
      setShowNotification(true);
      setNotificationType("Danger");
    }
  }, [error]);

  const statusOptions = [{ label: props.t("ACTIVE"), value: "ACTIVE" },
    { label: props.t("PASSIVE"), value: "PASSIVE" }];

  const typeOptions = [{ label: props.t("SENDER"), value: "SENDER" },
    { label: props.t("RECEIVER"), value: "RECEIVER" }];

  function onChangeStatus(e) {
    setStatus(e);
  }

  function onChangeType(e) {
    setType(e);
  }

  function handleChangeUrl(e) {
    setUrl(e.target.value);
    setLeadIntegrationDefinition({
      ...leadIntegrationDefinition,
      [e.target.name]: e.target.value
    });
  }

  function handleChangeSecret(e) {
    setSecretKey(e.target.value);
    setLeadIntegrationDefinition({
      ...leadIntegrationDefinition,
      [e.target.name]: e.target.value
    });
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteLeadIntegrationDefinition}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Notification
            message={message}
            show={showNotification}
            callbackShow={handleClose}
            type={notificationType}
          />
          <Breadcrumbs title={props.t("Lead Integration Definition")} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    refreshButton={true}
                    handleRefreshClick={handleRefresh}
                    columns={columns}
                    data={leadIntegrationDefinitionList}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    handleAddClick={handleLeadIntegrationDefinitionClicks}
                    customPageSize={25}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit
                        ? `${props.t("Edit")}`
                        : `${props.t("Add")}`}
                    </ModalHeader>
                    <ModalBody>
                      <div type="form">
                        <Row form="true">
                          <Col className="col-12">
                            <div className="mb-3">
                              <Alert color="warning">{props.t("TYPE_INPUT_DESC")}</Alert>
                              <Label className="form-label">{props.t("Integration Type")}</Label>
                              <Select
                                id="integration-type"
                                className="bs-select"
                                value={type}
                                onChange={(e) => {
                                  onChangeType(e);
                                }}
                                options={typeOptions}
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Status")}</Label>
                              <Select
                                id="integration-status"
                                className="bs-select"
                                value={status}
                                onChange={(e) => {
                                  onChangeStatus(e);
                                }}
                                options={statusOptions}
                              />
                            </div>
                            <div className="mb-3">
                              {type.value === "RECEIVER" ? (
                                <Alert
                                  color="warning">{props.t("RECEIVER_URL_INPUT")}</Alert>) : (type.value === "SENDER" ?
                                <Alert color="warning">{props.t("SENDER_URL_INPUT")}</Alert> : null)}
                              <Label className="form-label">{props.t("URL")}</Label>
                                <Input
                                  name="url"
                                  type="url"
                                  onChange={handleChangeUrl}
                                  placeholder="https://senderOrReceiver.techlabcrm.com.tr"
                                  value={url || ""}
                                  required={true}
                                  invalid={url === ""}
                                />
                            </div>
                            {!isEdit?(
                            <div className="mb-3">
                              {type.value === "RECEIVER" ? (
                                <Alert
                                  color="warning">{props.t("RECEIVER_SECRET_KEY_INPUT")}</Alert>) : type.value === "SENDER" ?
                                (<Alert color="warning">{props.t("SENDER_SECRET_KEY_INPUT")}</Alert>) : null}
                              {type.value === "RECEIVER" ? (
                                <Alert color="danger">{props.t("SECRET_KEY_WARNING")}</Alert>) : null}
                              <Label className="form-label">{props.t("Secret Key")}</Label>
                              <Input
                                name="secretKey"
                                type="text"
                                onChange={handleChangeSecret}
                                disabled={type.value === "RECEIVER"}
                                value={secretKey || ""}
                                invalid={type.value === "SENDER" && secretKey === ""}
                              />
                            </div>
                            ):null}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success"
                                onClick={handleSubmit}
                                disabled={status.value === "" || type.value === "" || url === "" || (type.value === "SENDER" && secretKey === "")}
                              >
                                {props.t("Save")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </ModalBody>
                  </Modal>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
LeadIntegrationDefinition.propTypes = {
  t: PropTypes.any,
  leadIntegrationDefs: PropTypes.array,
  onGetLeadIntegrationDefinitions: PropTypes.func,
  onAddNewLeadIntegrationDefinition: PropTypes.func,
  onDeleteLeadIntegrationDefinition: PropTypes.func,
  onUpdateLeadIntegrationDefinition: PropTypes.func
};

export default withTranslation()(LeadIntegrationDefinition);
