
/* CUSTOMERS */
export const GET_CUSTOMERS = "GET_CUSTOMERS"
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS"
export const GET_CUSTOMERS_FAIL = "GET_CUSTOMERS_FAIL"

/**
 * GET CUSTOMER
 */
export const GET_CUSTOMER_BY_ID = "GET_CUSTOMER_BY_ID"
export const GET_CUSTOMER_BY_ID_SUCCESS = "GET_CUSTOMER_BY_ID_SUCCESS"
export const GET_CUSTOMER_BY_ID_FAIL = "GET_CUSTOMER_BY_ID_FAIL"

/**
 * add CUSTOMER
 */
export const ADD_NEW_CUSTOMER = "ADD_NEW_CUSTOMER"
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS"
export const ADD_CUSTOMER_FAIL = "ADD_CUSTOMER_FAIL"

/**
 * Edit CUSTOMER
 */
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER"
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS"
export const UPDATE_CUSTOMER_FAIL = "UPDATE_CUSTOMER_FAIL"

/**
 * Delete CUSTOMER
 */
export const DELETE_CUSTOMER = "DELETE_CUSTOMER"
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS"
export const DELETE_CUSTOMER_FAIL = "DELETE_CUSTOMER_FAIL"

/**
 * ASSIGN REPRESENTATIVE CUSTOMER
 */
export const ASSIGN_REPRESENTATIVE = "ASSIGN_REPRESENTATIVE"
export const ASSIGN_REPRESENTATIVE_SUCCESS = "ASSIGN_REPRESENTATIVE_SUCCESS"
export const ASSIGN_REPRESENTATIVE_FAIL = "ASSIGN_REPRESENTATIVE_FAIL"

/**
 * ASSIGN SEGMENT CUSTOMER
 */
export const ASSIGN_SEGMENT = "ASSIGN_SEGMENT"
export const ASSIGN_SEGMENT_SUCCESS = "ASSIGN_SEGMENT_SUCCESS"
export const ASSIGN_SEGMENT_FAIL = "ASSIGN_SEGMENT_FAIL"

/**
 * Lead Criteria
 */
export const LEAD_CRITERIA = "LEAD_CRITERIA"
export const LEAD_CRITERIA_SUCCESS = "LEAD_CRITERIA_SUCCESS"
export const LEAD_CRITERIA_FAIL = "LEAD_CRITERIA_FAIL"

/**
 * Transfer Data
 */
export const TRANSFER_LEAD_DATA = "TRANSFER_LEAD_DATA"
export const TRANSFER_LEAD_DATA_SUCCESS = "TRANSFER_LEAD_DATA_SUCCESS"
export const TRANSFER_LEAD_DATA_FAIL = "TRANSFER_LEAD_DATA_FAIL"
