import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";

// Column
import { withTranslation } from "react-i18next";
import DeleteModal from "../../components/Common/DeleteModal";
import Accordion from "../../components/Common/Accordion";
import { get, post } from "../../helpers/axios_with_headers";
import axios from "axios";
import { facebook } from "../../config";

const Integration = props => {
  //meta title
  document.title = `CRM | ${props.t("Integration")}`;

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [pages, setPages] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loginResponse, setLoginResponse] = useState({});
  const [isPermissionGranted, setIsPermissionGranted] = useState(false);

  useEffect(() => {
    checkLoginState();
    checkOldDataPermission();
  }, []);

  function checkLoginState() {
    FB.getLoginStatus(function(response) {
      if (response.status === "connected") {
        setIsLoggedIn(true);
        testAPI();
      } else if (response.status === "not_authorized") {
        logout();
        setIsLoggedIn(false);
      } else {
        setIsLoggedIn(false);
      }
    });
  }

  function sendPageAccessToken(res) {
    const userObj = Object.assign({
      userId: res.authResponse.userID,
      shortUserAccessToken: res.authResponse.accessToken,
      targetUrl: process.env.REACT_APP_API_URL + "/leadIntegration/facebook"
    });

    axios.post("https://integration2.techlabcrm.com.tr/INTEGRATION2/facebookDefinition/auth", userObj).then(r => {
      if (r.status === 200) {
        console.log("backendRes", r);
      }
    });

    const req = { type: "LOGIN", status: "SUCCESS" };
    post(process.env.REACT_APP_API_URL + "/facebookIntegrationLog", req).then(r => {
      if (r.status === 200) {
        console.log("backendRes", r);
      }
    });
  }


  function sendCodeToBackend(res) {
    console.log("sendCodeToBackend",res)
    const userObj = Object.assign({
      oldDataPermission: isPermissionGranted,
      code: res.authResponse.code,
      targetUrl: process.env.REACT_APP_API_URL + "/leadIntegration/facebook"
    });

    axios.post("https://integration2.techlabcrm.com.tr/INTEGRATION2/facebookDefinition/auth", userObj).then(r => {
      if (r.status === 200) {
        console.log("backendRes", r);
      }
    });
  }

  function checkOldDataPermission() {
    get(process.env.REACT_APP_API_URL + "/CRM/systemParameter/findByCode/GET_OLD_FACEBOOK_LEADS").then(r => {
      if (r.status === 200) {
        console.log("checkOldDataPermission", r);
        if (r.data.value !== undefined) {
          setIsPermissionGranted(r.data.value);
        }
      }
    });
  }

  function login() {
    FB.login(function(response) {
      console.log("fbLoginResponse:", response);
      sendPageAccessToken(response);
      setLoginResponse(response);
      testAPI();
      setIsLoggedIn(true);
    }, { scope: "pages_show_list,leads_retrieval,ads_management,pages_manage_ads,pages_manage_metadata,public_profile" });
  }

  function loginSystemUserAccessToken() {
    FB.login(function(response) {
      //sendPageAccessToken(response);
      sendCodeToBackend(response)
      setIsLoggedIn(true);
      setLoginResponse(response);
      testAPI();
    }, {
      config_id: "1133300564353598",
      response_type: "code",
      override_default_response_type: true
    });
  }

  function testAPI() {
    window.FB.api("/me/permissions", function(response) {
      setPermissions(response.data);
      console.log("permissions", response.data);
    });
  }

  function logout() {
    window.FB.api("/me/permissions", "delete", null, () => window.FB.logout());
    const req = { type: "LOGOUT", status: "SUCCESS" };
    post(process.env.REACT_APP_API_URL + "/facebookIntegrationLog", req).then(r => {
      if (r.status === 200) {
        console.log("backendRes", r);
      }
    });
    setIsLoggedIn(false);
    setDeleteModal(false);
    setPages([]);
  }

  const asChild = (
    <React.Fragment>
      <Row>
        {isLoggedIn ? (
          <React.Fragment>
            <Col className="col-6 mt-2 align-items-center">
              <Button className="kep-login-facebook"
                      onClick={loginSystemUserAccessToken}>
                <i className="bxc bx bxl-facebook-circle font-size-30"></i>
                {props.t("Continue With Facebook For Business")}
              </Button>
            </Col>
            <Col className="col-6 mt-2 align-items-center">
              <Button className="kep-login-facebook"
                      onClick={() => setDeleteModal(true)}>
                <i className="bxc bx bxl-facebook-circle font-size-30"></i>
                {props.t("Logout")}
              </Button>
            </Col>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Col className="col-3" />
            <Col className="col-6">
              <Button className="kep-login-facebook"
                      onClick={loginSystemUserAccessToken}>
                <i className="bxc bx bxl-facebook-circle font-size-30"></i>
                {props.t("Continue With Facebook For Business")}
              </Button>
            </Col>
            <Col className="col-3" />
          </React.Fragment>
        )}
      </Row>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={logout}
            onCloseClick={() => setDeleteModal(false)}
            title={props.t("Are you sure ?")}
            message={props.t("Continuing with this process will remove the permissions you have given and prevent the application from working properly.")}
          />
          <Row className="mt-5">
            <Accordion
              childComponent={asChild}
              user={loginResponse}
              isPermissionGranted={isPermissionGranted}
            />
            {pages.length > 0 && (
              <Card className="mini-stats-wid">
                <CardBody>
                  <CardTitle>
                    {props.t("You have granted the following permissions to this page(s): ")} {pages}
                  </CardTitle>
                  {permissions !== undefined && permissions.length > 0 ? (
                    <ul className="verti-timeline list-unstyled">
                      {permissions.map((permission, key) => (
                        <li key={key} className="event-list active">
                          <div className="d-flex">
                            <div className="flex-sm-wrap">
                              <div> {permission.permission}</div>
                            </div>
                            <div className="event-timeline-dot">
                              {permission.status === "granted" ?
                                <i className="bx bx-check-circle font-size-18 text-success" /> :
                                <i className="bx bx-x-circle font-size-18 text-error" />}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : null}
                </CardBody>
              </Card>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Integration.propTypes = {
  t: PropTypes.any
};

export default withRouter(withTranslation()(Integration));
