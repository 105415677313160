import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  CSVExport,
  Search
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
//Import Breadcrumb
import "./datatables.scss";
import PropTypes from "prop-types";

const CustomDataTable = (props) => {

  const [page, setPage] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(props.sizePerPage || 20);
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState(props.columns);
  const [selectedRows, setSelectedRows] = useState([]);

  const defaultSorted = props.sortingAlg !== undefined ? props.sortingAlg : [
    {
      dataField: "createDate",
      order: "desc"
    }
  ];

  useEffect(() => {
    setTableData(props.data);
    setSelectedRows([]);
  }, [props.data]);

  useEffect(() => {
    if (props.sizePerPage) {
      setSizePerPage(props.sizePerPage);
    }
  }, [props.sizePerPage]);

  const pageOptions = {
    sizePerPage: sizePerPage,
    pageStartIndex: 1,
    hideSizePerPage: true,
    totalSize: tableData.length, // replace later with size(customers),
    custom: props.customPagination === true ? true : false
  };

  // Select All Button operation
  const selectRow = props.selectRow ? {
    mode: "checkbox",
    hideSelectColumn: false,
    hideSelectAll: true,
    onSelect: (row, isSelect) => {
      if (isSelect) {
        selectedRows.push(row);
        props.selectedEvent(selectedRows);
      } else {
        const filteredRows = selectedRows.filter(
          selectedRow => selectedRow.id !== row.id
        );
        setSelectedRows(filteredRows);
        props.selectedEvent(filteredRows);
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        const selecteds = rows.map(row => selectedRows.push(row));
        props.selectedEvent(selecteds);
      } else {
        setSelectedRows([]);
        props.selectedEvent([]);
      }
    }
  } : {
    mode: "checkbox",
    hideSelectColumn: true,
    hideSelectAll: true
  };

  const rowEvents = props.rowEvents ? {
    onClick: (e, row, rowIndex) => {
      props.rowEvents(row);
    }
  } : null;

  useEffect(() => {
    if (props.clearSelected) {
      setSelectedRows([]);
    }
  }, [props.clearSelected]);

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;

  return (
    <PaginationProvider
      pagination={paginationFactory(pageOptions)}
      hideSizePerPage={false}
      keyField="id"
      columns={columns}
      data={tableData}
    >
      {({ paginationProps, paginationTableProps }) => (
        <ToolkitProvider
          keyField="id"
          columns={columns}
          data={tableData}
          exportCSV={{
            fileName: `${props.title}.csv`,
            ignoreHeader: false,
            noAutoBOM: false
          }}
          search={props.search}
        >
          {toolkitProps => (
            <React.Fragment>
              <Row className="mt-4">
                <Col md="4">
                  <div className="search-box me-2 mb-2 d-inline-block">
                    {props.search === true ? (<div className="position-relative">
                      <SearchBar
                        {...toolkitProps.searchProps}
                      />
                      <i className="bx bx-search-alt search-icon" />
                    </div>) : null}
                  </div>
                </Col>
                {props.export ? (
                  <React.Fragment>
                    <Col md="7">
                      {props.toolbar}
                    </Col>
                    <Col md="1" cal>
                      <ExportCSVButton className="btn btn-outline-info w-sm m-o" {...toolkitProps.csvProps}><span>
                        <i className="bx bx-export font-size-16" />
                      </span></ExportCSVButton>
                    </Col>
                  </React.Fragment>
                ) : (
                  <Col md="8">
                    {props.toolbar}
                  </Col>)}
              </Row>

              <Row className="mt-2">
                <span className="text-center text-dark">
                  <h5>{props.title}</h5>
                </span>
                <BootstrapTable
                  keyField={"id"}
                  responsive={true}
                  bordered={false}
                  striped={false}
                  defaultSorted={defaultSorted}
                  selectRow={selectRow}
                  rowEvents={rowEvents}
                  {...toolkitProps.baseProps}
                  {...paginationTableProps}
                />
              </Row>
            </React.Fragment>
          )}
        </ToolkitProvider>
      )}
    </PaginationProvider>
  );
};

CustomDataTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  title: PropTypes.string,
  rowEvents: PropTypes.func,
  selectRow: PropTypes.bool,
  export: PropTypes.bool,
  selectedEvent: PropTypes.func,
  clearSelected: PropTypes.any,
  customerSelect: PropTypes.node,
  toolbar: PropTypes.any,
  search: PropTypes.bool,
  customPagination: PropTypes.bool,
  sortingAlg: PropTypes.any,
};

export default CustomDataTable;
